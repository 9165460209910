import React from 'react'
import { Helmet } from 'react-helmet-async'
import l10n from 'properties/translations'
import WebsiteMonitoring from 'components/shared/WebsiteMonitoring'

type Props = {
  title: string
}

const HelmetComponent = ({ title }) => (
  <Helmet
    defaultTitle={l10n.screenTitles.default}
    titleTemplate={`%s | ${l10n.screenTitles.default}`}
  >
    <title>{title}</title>
  </Helmet>
)

const ScreenTitle = ({ title = '' }: Props) => {
  return window.isProduction ? ( // window.isProduction is set in public/js/monitoring.js
    <WebsiteMonitoring>
      <HelmetComponent title={title} />
    </WebsiteMonitoring>
  ) : (
    <HelmetComponent title={title} />
  )
}

export default ScreenTitle
