// @flow
import userTypesEnum from 'constants/enums/userTypes'
import { get } from 'lodash'
import { isTestingEnv } from 'util/EnvironmentHelper'
import StringHelper from 'util/StringHelper'

const kReportError = 'reportError'

export function manuallyReportError(error: Error) {
  if (isTestingEnv()) {
    // eslint-disable-next-line no-console
    console.error(kReportError, error)
  }

  if (!window.ineum) return
  window.ineum(kReportError, error)
}

let instanaTracked = false

export const trackPageInInstana = ({ customer, me, channelPartner }) => {
  if (!me || !me.emailAddress) return
  if (instanaTracked) return

  // eslint-disable-next-line
  const { firstName, lastName } = me
  const roles = get(me, 'userRoles', [])
  const channelPartnerName = get(channelPartner, 'name', null)
  const channelPartnerId = get(channelPartner, 'id', null)
  const userType = get(me, 'userType')
  const userEmail = get(me, 'emailAddress')
  const userId = get(me, 'userId')
  const customerId = get(customer, 'id')
  const opportunityId = get(customer, 'opportunityId')
  const userRolesSerialized = roles.join()
  const accessModesSerialized = get(me, 'accessModes', []).join()
  const userName = StringHelper.getCustomerFullName(firstName, lastName)

  instanaTracked = true

  const conditionalUserId =
    userType === userTypesEnum.borrower || userType === userTypesEnum.integrator
      ? userId
      : opportunityId

  attachInstanaMetadata(conditionalUserId, userName, userEmail, {
    userId,
    customerId,
    opportunityId,
    userType,
    userRolesSerialized,
    channelPartnerName,
    channelPartnerId,
    accessModesSerialized
  })
}

export const attachInstanaMetadata = (
  userId: string,
  userName: string,
  userEmail: string = null,
  meta: any
) => {
  if (!window.ineum) return
  window.ineum('user', userId, userName, userEmail)
  for (const key in meta) {
    window.ineum('meta', key, meta[key])
  }
}

export default {
  attachInstanaMetadata,
  manuallyReportError
}
