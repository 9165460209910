// @flow
import React from 'react'
import Client from 'gql/clientNew'
import type { Node } from 'react'
import ReactSessionApi from 'react-session-api'
import { Grid, Typography } from '@material-ui/core'
import { withRouter, type Match } from 'react-router-dom'
import cx from 'classnames'
import { demoCustomer } from 'constants/enums/demoUser'
import DateHelper from 'util/DateHelper'
import { isTestingEnv } from 'util/EnvironmentHelper'
import { customerLanguageKey } from 'util/CustomerHelper'
import {
  cloneDeep,
  set,
  parseInt as lodashParseInt,
  some,
  find,
  noop,
  flow
} from 'lodash'
import browserAutocompleteKeys from 'constants/enums/browserAutocompleteKeys'
import userRoles from 'constants/enums/userRoles'
import placeholders from 'properties/placeholders'
import {
  editCustomerFlag,
  singleDobFieldFlagEnabled,
  landlineNumberEnabled,
  solarIntegratorPrequalificationEnabled,
  householdIncomeOnHomeP2XApplications
} from 'util/FeatureHelper'
import { localizedTranslations } from 'properties/translations'
import fieldsets, { fieldsetNames } from 'constants/fieldsets/customerFieldsets'
import testIds from 'constants/enums/testIds'
import addressTypes from 'constants/enums/addressTypes'
import userTypes from 'constants/enums/userTypes'
import errorKeys from 'constants/enums/errorKeys'
import localStorageKeys from 'constants/enums/localStorageKeys'
import LandlineWarning from 'components/shared/LandlineWarning'
import {
  DEBOUNCE_TIME,
  EMPTY_STRING,
  EMPTY_OBJECT,
  PHONE_NUMBER_VALIDATORS,
  NO_SPECIAL_CHARACTERS_VALIDATORS,
  COMPOUND_NAME_VALIDATORS,
  NAME_LENGTH,
  ENTER_CHARCODE,
  ENTER_KEYCODE,
  EMPTY_ARRAY
} from 'properties/properties'
import NumberFormat from 'react-number-format'
import {
  collectFormValues,
  buildAddresses,
  buildFormData,
  getProjectAddressType,
  initStateValues,
  addFullNameToUsersInList,
  canBypassInlineErrors,
  buildFieldInputProps,
  getUnlockedGridItemProps,
  checkRequiredField,
  isNotPrimaryOrUnknownAddressType,
  getIncomeErrorKeys,
  updateIncomeAlerts,
  getCurrentIncomeErrorKey
} from 'util/CustomerFormViewHelper'
import { getAssignee, isSolarLoanType } from 'util/PrequalificationHelper'
import PersonalIncomeField from 'components/shared/PersonalIncomeField'
import OtherHouseholdIncomeField from 'components/shared/OtherHouseholdIncomeField'
import DobFields from 'components/shared/DobFields'
import CustomerFullName from 'screens/customer/components/CustomerFullName'
import FieldErrorMessage from 'screens/customer/components/FieldErrorMessage'
import AlternativeAddressCheckbox from 'screens/customer/components/AlternativeAddressCheckbox'
import SsnField from 'components/shared/SsnField'
import AlternatePhoneNumber from 'screens/customer/components/AlternatePhoneNumber'
import AccessControl from 'components/shared/AccessControl'
import defaultTo from 'lodash/defaultTo'
import get from 'lodash/get'
import deviceVerificationStatusTypes from 'constants/enums/deviceVerificationStatusTypes'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import { Query } from '@apollo/client/react/components'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import AddressBody from 'screens/customer/components/AddressBody'
import AutoCompleteComboBox from 'components/shared/AutoCompleteComboBox'
import errorTypes from 'constants/enums/errorTypes'
import * as CustomerHelper from 'util/CustomerHelper'
import MonitoringHelper from 'util/MonitoringHelper'
import TranslationsContext from 'util/TranslationsContext'
import {
  type SalespersonResponse,
  type AutoCompleteComboBoxOption
} from 'util/TypesHelper'
import PrequalificationRouteContext from 'util/PrequalificationRouteContext'

import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import UserContext from 'util/UserContext'
import formValidator from './formValidator/FormValidator'
import PhoneNumberField from './PhoneNumberField'
import LockableField from './components/LockableField'
import CustomerEmail from '../../components/shared/CustomerEmail'
import Fieldset from './components/Fieldset'
import Address from './components/Address'
import AddressTypeDropdown from './components/AddressTypeDropdown'
import customerFields from '../../constants/enums/customerFields'
import customerFieldProperties from '../../constants/fieldsets/customerFieldProperties'
import fieldMasks from '../../constants/enums/fieldMasks'
import { type CustomerType, type FieldError } from './shapes/customer'
import { type AddressShape } from './shapes/address'
import ValidatePhoneAndEmailGraphql from './queries/ValidatePhoneAndEmail.graphql'
import CustomerPartnerQuery from './queries/CustomerPartnerQuery.graphql'
import ReferredEmailField from 'screens/customer/components/ReferredEmailField'
import PrequalificationFormContext from 'util/PrequalificationFormContext'

const {
  FIELDSET_ABOUT,
  FIELDSET_ABOUT_SINGLE_PAGE_PREQUAL,
  FIELDSET_APPLY,
  FIELDSET_PUBLIC_APPLICATION,
  FIELDSET_VERIFY_IDENTITY
} = fieldsetNames

const NON_PUBLIC_FIELDSET_COLLECTION = [
  FIELDSET_ABOUT,
  FIELDSET_ABOUT_SINGLE_PAGE_PREQUAL,
  FIELDSET_APPLY,
  FIELDSET_VERIFY_IDENTITY
]

const MAX_ROWS_NOTES = 4

type Props = {
  children: (child: Node, { submit: Function, reset: Function }) => Node,
  customer: CustomerType,
  collectFormValuesAndSubmit: Function,
  canEdit: boolean,
  fieldset?: string,
  classes: { [string]: string },
  isInfoEditable?: boolean,
  disableFields: boolean,
  accessModeContainer: { demoMode: boolean },
  emailAddressVerified: boolean,
  emailAlreadyInUse: boolean,
  isFormValid: boolean,
  addressIsModified: Function,
  onSubmit: Function,
  onChange?: Function,
  setFormValid: Function,
  ldFlags: Object,
  address: Object,
  client: Object,
  toggleValidationInProgress: Function,
  fieldErrors: FieldError[],
  clearFieldErrors: Function,
  inactiveForm: boolean,
  isEditingCustomer: boolean,
  isNewCustomer: Boolean,
  match: Match,
  inlineErrorsOnValidatePhoneAndEmail: { errObj: Object, fieldName?: string },
  setAnyFormError: Function
}

type State = {
  showAlternatePhoneNumber: boolean,
  addressMap: Map<string, AddressShape>,
  customer: {
    id: string,
    firstName: string,
    lastName: string,
    middleName: string,
    address: Object,
    email: string,
    notes: string,
    dobDay: string,
    dobMonth: string,
    dobYear: string,
    dobFull: string,
    phoneNumber: number,
    phoneNumberAlt1: string,
    salesperson: SalespersonResponse,
    assignedTo?: string
  },
  readMoreClicked: boolean,
  projectAddressType: Object,
  primaryAddressError: Object,
  mailingAddressError: Object,
  secondHomeOrRentalError: Object,
  customerPath: string,
  disclosureIdentifier: any,
  showMailingAddress: boolean,
  errorOccurred: boolean,
  inlineErrors: Object,
  isFormValid: boolean,
  validationInProgress: boolean,
  openAddressModal: any,
  unlockedFields: Array<Object>,
  notesError: string,
  emailAlreadyInUse: boolean,
  incomeAlerts: {
    highPersonalIncomeAlert: boolean,
    lowPersonalIncomeAlert: boolean
  },
  startEdit?: boolean,
  assignedSalesperson?: SalespersonResponse,
  emailErrorType: string,
  phoneErrorType: string,
  landlineWarning: string,
  titleAttestation?: boolean
}

type ErrorMessageProps = {
  field: string,
  translationsContext?: Object
}
class CustomerFormView extends React.Component<Props, State> {
  form: any = null
  formValidationTimeoutId: ?TimeoutID = undefined
  firstRef: boolean = false

  static contextType = PrequalificationFormContext

  constructor(props) {
    super(props)
    this.state = this.getInitialStateValues()
  }

  setInitialValues = () => {
    this.setState(this.getInitialStateValues())
  }

  static defaultProps = {
    fieldErrors: []
  }

  handleKeyDown = event => {
    const { inlineErrorsOnValidatePhoneAndEmail } = this.props
    const fieldName = event.target.name
    switch (fieldName) {
      case customerFields.phoneNumber:
        this.setState({ phoneErrorType: EMPTY_STRING })
        if (inlineErrorsOnValidatePhoneAndEmail) {
          inlineErrorsOnValidatePhoneAndEmail(
            EMPTY_OBJECT,
            errorTypes.failedFields.PHONE
          )
        }
        break
      case customerFields.email:
        this.setState({ emailErrorType: EMPTY_STRING })
        if (inlineErrorsOnValidatePhoneAndEmail) {
          inlineErrorsOnValidatePhoneAndEmail(
            EMPTY_OBJECT,
            errorTypes.failedFields.EMAIL
          )
        }
        break
      default:
        return null
    }
    const { startEdit } = this.state
    if (event.key === ENTER_KEYCODE) {
      if (this.props.canEdit || startEdit) {
        this.collectFormValuesAndSubmit()
      }
    }
  }

  componentDidMount() {
    this.addCustomValidators()
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  validatePhoneAndEmail = async (email, phoneNumber, customerId) => {
    const { guestClient } = new Client()
    const { isFormValid } = this.props
    const { inlineErrorsOnValidatePhoneAndEmail } = this.props

    // Only validate if the form is valid
    if (!isFormValid) return { isFormValid }
    if (!email) return {}
    try {
      await guestClient.query({
        query: ValidatePhoneAndEmailGraphql,
        variables: { emailAddress: email, phoneNumber, customerId }
      })
      if (inlineErrorsOnValidatePhoneAndEmail) {
        inlineErrorsOnValidatePhoneAndEmail(EMPTY_OBJECT)
      }
    } catch (error) {
      MonitoringHelper.manuallyReportError(error)

      // Get server errors for email and phone field
      const serverErrorMessages = CustomerHelper.getServerErrorMessageObject(
        error.graphQLErrors
      )
      const emailErrorType = CustomerHelper.getErrorType(serverErrorMessages, [
        customerFields.email,
        customerFields.primaryApplicantEmailAddress
      ])
      const phoneErrorType = CustomerHelper.getErrorType(serverErrorMessages, [
        customerFields.phoneNumber,
        customerFields.primaryApplicantPhoneNumber
      ])
      if (inlineErrorsOnValidatePhoneAndEmail) {
        inlineErrorsOnValidatePhoneAndEmail(serverErrorMessages)
      }
      await this.setState({ emailErrorType, phoneErrorType })
    }
    return {}
  }

  validateFormFields = (inlineErrors = {}) =>
    Object.keys(inlineErrors).forEach(errType => {
      // eslint-disable-next-line react/no-string-refs
      if (this.refs[errType]) {
        // eslint-disable-next-line react/no-string-refs
        this.refs[errType].validate()
      }
    })

  formatFormValues = async () => {
    if (!this.form) return null
    const {
      customer: { id: customerId },
      toggleValidationInProgress,
      accessModeContainer: { demoMode },
      fieldset
    } = this.props

    const { addressMap, customer } = this.state
    const formValues = collectFormValues(customer, this.form)
    set(formValues, 'addresses', buildAddresses(addressMap))
    toggleValidationInProgress()

    const inlineErrors = await this.validatePhoneAndEmail(
      formValues.email,
      formValues.phoneNumber,
      customerId
    )
    toggleValidationInProgress()

    if (
      !isEmpty(inlineErrors) &&
      !canBypassInlineErrors(fieldset, inlineErrors)
    ) {
      this.setState({ inlineErrors })
      this.validateFormFields(inlineErrors)
      return null
    }

    return buildFormData(customerId, formValues, demoMode)
  }

  collectFormValuesAndSubmit = async () => {
    const { onSubmit } = this.props
    const submitValues = await this.formatFormValues()
    if (submitValues) onSubmit(submitValues)
  }

  addCustomValidators = () => {
    ValidatorForm.addValidationRule('validDate', bits => {
      const numericBits = bits.map(bit => lodashParseInt(bit))

      if (numericBits.some(bit => !bit)) return true

      return DateHelper.isValid(...numericBits)
    })

    ValidatorForm.addValidationRule(
      'isValidCalendarDate',
      formValidator.isValidCalendarDate
    )

    ValidatorForm.addValidationRule(
      'noBlankSpaces',
      formValidator.noBlankSpaces
    )

    // EX-3568: This validation rule does not work correctly.
    // When this validation rule is set, the value is always undefined and we do not set the state until after this has been set.
    // In order to fix this, we need call this validation rule after the state has been changed.
    // Some edge cases of this work based on how the input field is updated, but the majority of the time the state value of the inlineError is undefined
    ValidatorForm.addValidationRule(
      'isUniqueEmail',
      () => !this.state.inlineErrors[customerFields.email]
    )

    ValidatorForm.addValidationRule(
      'isValidLastName',
      formValidator.isValidLastName
    )
  }

  getInitialStateValues = () => {
    const {
      customer,
      accessModeContainer: { demoMode = false }
    } = this.props

    const phoneNumberAlt1 = get(customer, 'phoneNumberAlt1')
    // Map addresses array to an object that is easier to work with
    const addresses = get(customer, 'addresses', [])
    const addressMap = new Map([])

    addresses.forEach(address => {
      const { addressType } = address
      addressMap.set(addressType, omit(address, '__typename'))
    })

    const projectAddressType = getProjectAddressType(addressMap)

    const customerWithDob = {
      ...customer,
      ...CustomerHelper.dobFieldsFromCustomerWeblinksMetadata(customer)
    }

    const initialCustomer = demoMode
      ? {
          ...customerWithDob,
          ...demoCustomer
        }
      : customerWithDob

    return initStateValues(
      Boolean(phoneNumberAlt1),
      addressMap,
      initialCustomer,
      projectAddressType
    )
  }

  clearFieldsErrorMessages = () => {
    const {
      primaryAddressError,
      mailingAddressError,
      secondHomeOrRentalError,
      notesError
    } = this.state

    // If errors messages occured after user submits the form, then clear those when typing

    if (primaryAddressError !== '') {
      this.setState({ primaryAddressError: '' })
    }
    if (mailingAddressError !== '') {
      this.setState({ mailingAddressError: '' })
    }
    if (secondHomeOrRentalError !== '') {
      this.setState({ secondHomeOrRentalError: '' })
    }
    if (notesError !== '') {
      this.setState({ notesError: '' })
    }
  }

  findEmptyAddressFields = addresses => {
    const emptyAddressFields = addresses.map(address => {
      return some(address, isEmpty)
    })
    return emptyAddressFields.includes(true)
  }

  addressMapMissingPrimary = (addresses, setAnyFormError) => {
    const result = find(addresses, { addressType: addressTypes.primary })
    const isMissingPrimary = Boolean(!result)
    return isMissingPrimary
  }

  handleFormChange = () => {
    const {
      setFormValid,
      onChange = noop,
      anyFormError,
      setAnyFormError
    } = this.props
    onChange()
    clearTimeout(this.formValidationTimeoutId)

    if (setFormValid && this.form) {
      this.formValidationTimeoutId = setTimeout(() => {
        this.form.isFormValid().then(isValid => {
          setFormValid(isValid)
        })
      })
      // the setTimeout is needed to get the real value with ValidatorForm#isFormValid
    }

    const checkAddress = cloneDeep(this.state.addressMap)
    const values = [...checkAddress.values()]

    const hasEmptyAddressFields =
      this.findEmptyAddressFields(values) ||
      this.addressMapMissingPrimary(values, setAnyFormError)

    clearTimeout(this.formValidationTimeoutId)
    if (setFormValid && typeof this.form !== 'undefined') {
      this.formValidationTimeoutId = setTimeout(() => {
        if (isTestingEnv() && (!this.form || !this.form.isFormValid)) return
        if (!this.form) return
        this.form.isFormValid().then(isValid => {
          if (anyFormError) {
            setAnyFormError(!isValid)
          }

          if (
            isValid &&
            (this.state.customer.email === EMPTY_STRING ||
              hasEmptyAddressFields)
          ) {
            setFormValid(false)
          } else {
            setFormValid(isValid)
          }
        })
      })
    }
    // If errors messages occured after user submits the form, then clear those when typing
    this.clearFieldsErrorMessages()
  }

  checkForAndClearInlineErrors = nameArray => {
    const inlineErrors = { ...this.state.inlineErrors }

    nameArray.forEach(name => {
      if (inlineErrors[name]) {
        delete inlineErrors[name]
        this.validateFormFields(inlineErrors)
      }
    })

    return { inlineErrors }
  }

  updateCustomerField = (name, newValue) => {
    let stateChanges = {}
    if (name === customerFields.email) {
      stateChanges.emailAlreadyInUse = false
    }

    this.setState(prevState => {
      const customer = cloneDeep(prevState.customer)
      set(customer, name, newValue)
      return {
        ...stateChanges,
        customer,
        ...this.checkForAndClearInlineErrors([name])
      }
    })
  }

  shouldShowAgeAlert = year => {
    if (year >= 1000 && CustomerHelper.isYearOutOfRange(year)) {
      this.setState({ dobAlert: true })
    } else {
      this.setState({ dobAlert: false })
    }
  }

  handleDOBFull = date => {
    const dobMonth = DateHelper.monthFromMMDDYYYYDate(date)
    const dobDay = DateHelper.dayFromMMDDYYYYDate(date)
    const dobYear = DateHelper.yearFromMMDDYYYYDate(date)

    if (dobYear) {
      this.shouldShowAgeAlert(dobYear)
    }
    this.updateCustomerField(customerFields.dobMonth, dobMonth)
    this.updateCustomerField(customerFields.dobDay, dobDay)
    this.updateCustomerField(customerFields.dobYear, dobYear)
  }

  getAddressValue = (name, defaultValue = EMPTY_STRING) => {
    const address = defaultTo(this.props.address, this.state.customer.address)
    return get(address, name) || defaultValue
  }

  onFieldEdit = name => event => {
    const { checked, value } = event.target
    if (name === customerFields.dobFull) {
      this.handleDOBFull(value)
    }

    if (name !== customerFields.dobFull) {
      this.updateCustomerField(name, checked || value)
    }
    if (name === customerFields.personalIncome) {
      const incomeErrors = getIncomeErrorKeys(value)
      const incomeErrorKey = getCurrentIncomeErrorKey(incomeErrors)

      this.setState({
        incomeAlerts: {
          ...updateIncomeAlerts(incomeErrorKey)
        }
      })
    }
    if (name === customerFields.dobYear) {
      this.shouldShowAgeAlert(value)
    }
  }

  onClickReadMorePersonalIncome = () => {
    this.setState({ readMoreClicked: true })
  }

  getErrorMessagesRequiredWithL10n = (
    field,
    translationsContext
  ): ErrorMessageProps => {
    const { customer, fieldset } = this.props
    const l10n =
      fieldset === FIELDSET_PUBLIC_APPLICATION
        ? translationsContext
        : localizedTranslations(customerLanguageKey(customer))
    return this.isRequiredField(field) ? [l10n.formFields.required] : []
  }

  getValidatorsRequired = field => {
    const { customerVerified } = this.context
    const { isNewCustomer, isEditingCustomer } = this.props

    const validators =
      !customerVerified && !isNewCustomer && !isEditingCustomer
        ? EMPTY_ARRAY
        : ['required']
    return this.isRequiredField(field) ? validators : EMPTY_ARRAY
  }

  toggleAlternatePhoneNumber = () =>
    this.setState(prevState => {
      const { customer, showAlternatePhoneNumber } = prevState

      return {
        customer: {
          ...customer,
          ...(showAlternatePhoneNumber
            ? {
                // If removing alternate phone number, clear that value
                phoneNumberAlt1: ''
              }
            : {})
        },
        showAlternatePhoneNumber: !showAlternatePhoneNumber
      }
    })

  lockedFields = () => {
    const {
      customer: { firstName, lastName, deviceVerificationStatus },
      fieldset
    } = this.props

    const lockedFields = {
      [customerFields.phoneNumber]:
        deviceVerificationStatus === deviceVerificationStatusTypes.verified,
      [customerFields.fullName]:
        firstName && lastName && fieldset !== FIELDSET_PUBLIC_APPLICATION // Required - must return bool,
    }
    this.state.unlockedFields.forEach(field => {
      lockedFields[field] = false
    })

    return lockedFields
  }

  isRequiredField = (field, runValidations = true) => {
    if (!runValidations) return false

    const { fieldset } = this.props
    const { projectAddressType, showMailingAddress } = this.state

    return checkRequiredField(
      field,
      fieldset,
      projectAddressType,
      showMailingAddress
    )
  }

  onAddressMapEdit = async ({ address, addressType, validationFields }) => {
    this.setState(
      prevState => {
        const addressMap = cloneDeep(prevState.addressMap)
        addressMap.set(addressType, address)

        return {
          addressMap,
          ...this.checkForAndClearInlineErrors(validationFields)
        }
      },
      () => this.handleFormChange()
    )
  }

  toggleMailingAddress = () => {
    this.setState((prevState: State) => {
      const { addressMap, showMailingAddress } = prevState
      const updatedAddressMap = cloneDeep(addressMap)

      // If toggling off, remove mailing address if necessary
      if (updatedAddressMap.has(addressTypes.mailing)) {
        updatedAddressMap.delete(addressTypes.mailing)
      }

      return {
        showMailingAddress: !showMailingAddress,
        addressMap: updatedAddressMap
      }
    })
  }

  // TODO: Can this be removed?
  labelIsRequired = () => false

  onAddressTypeChange = (event: Object) => {
    event.persist()

    this.setState((prevState: State) => {
      const { addressMap, projectAddressType } = prevState
      const updatedAddressMap = cloneDeep(addressMap)
      const newProjectAddressType = event.target.value

      // Update any existing references
      if (updatedAddressMap.has(projectAddressType)) {
        const address = updatedAddressMap.get(projectAddressType)
        address.addressType = newProjectAddressType
        updatedAddressMap.set(newProjectAddressType, address)
        updatedAddressMap.delete(projectAddressType)
      }

      return {
        addressMap: updatedAddressMap,
        projectAddressType: newProjectAddressType
      }
    }, this.handleFormChange)
  }

  onAdminChange = (event: Event, value: SalespersonResponse) => {
    const salespersonId = value.id
    const { customer = {} } = this.state
    const { salesperson } = customer

    this.setState({
      customer: {
        ...customer,
        salesperson: {
          ...salesperson,
          id: salespersonId
        }
      },
      assignedSalesperson: value
    })
    this.handleFormChange()
  }

  getAssigneeEmail = (): ?string => {
    const {
      customer: { assignedTo }
    } = this.state

    const assignee = getAssignee()
    return assignee || assignedTo
  }

  handleKeyPress = e => {
    if (e.charCode === ENTER_CHARCODE) {
      this.collectFormValuesAndSubmit()
    }
  }

  displayLandlineConfirmModal = () => {
    this.setState({ landlineWarning: true })
  }

  hideLandlineConfirmModal = async () => {
    this.setState({ landlineWarning: false })
  }

  allowLandlineNumber = () => {
    ReactSessionApi.set(localStorageKeys.allowedLandline, true)
    this.hideLandlineConfirmModal()
    this.submitWrapperCheck()
  }

  displayLandlineWarning = () => {
    const { landlineWarning } = this.state
    if (ReactSessionApi.get(localStorageKeys.allowedLandline)) {
      return undefined
    }
    return (
      <LandlineWarning
        landlineWarning={landlineWarning}
        displayLandlineConfirmModal={this.displayLandlineConfirmModal}
        hideLandlineConfirmModal={this.hideLandlineConfirmModal}
        allowLandlineNumber={this.allowLandlineNumber}
      />
    )
  }

  submitWrapperCheck = () => {
    const { ldFlags } = this.props
    const landlineNumberFlagEnabled = landlineNumberEnabled(ldFlags)
    const { emailErrorType, phoneErrorType } = this.state
    const phoneOrEmailError =
      (phoneErrorType && phoneErrorType !== errorKeys.phoneNumberIsOptedOut) ||
      emailErrorType
    if (
      landlineNumberFlagEnabled &&
      isEmpty(emailErrorType) &&
      phoneErrorType === errorKeys.phoneNumberIsLandline
    ) {
      this.collectFormValuesAndSubmit()
    }
    if (!phoneOrEmailError) {
      this.collectFormValuesAndSubmit()
    }
  }

  render() {
    const {
      canEdit,
      fieldset,
      classes,
      disableFields,
      ldFlags,
      accessModeContainer: { demoMode },
      emailAddressVerified,
      addressIsModified,
      fieldErrors,
      inactiveForm = false,
      isEditingCustomer = false,
      match,
      setAnyFormError
    } = this.props
    const {
      showAlternatePhoneNumber,
      addressMap,
      customer: {
        id,
        firstName,
        middleName,
        lastName,
        email,
        notes,
        dobDay,
        dobMonth,
        dobYear,
        dobFull,
        phoneNumber,
        phoneNumberAlt1,
        salesperson,
        assignedTo = this.getAssigneeEmail()
      },
      readMoreClicked,
      projectAddressType,
      primaryAddressError,
      mailingAddressError,
      secondHomeOrRentalError,
      showMailingAddress,
      notesError,
      incomeAlerts,
      phoneErrorType,
      emailErrorType
    } = this.state

    const { customerVerified, customerLoanType } = this.context
    const lockedFields = this.lockedFields()
    const customerIdParamExists = match.params.customerId

    const alternatePhoneError = fieldErrors.find(
      error => error.failedField === errorTypes.failedFields.ALTERNATE_PHONE
    )

    const inputProps = {
      classes: {
        input: !canEdit ? classes.disabledInputText : undefined
      },
      onKeyPress: this.handleKeyPress
    }

    return (
      <TranslationsContext.Consumer>
        {l10n => {
          const baseFieldProps = {
            classes,
            demoMode,
            getErrorMessagesRequired: this.getErrorMessagesRequiredWithL10n,
            getValidatorsRequired: this.getValidatorsRequired,
            isRequiredField: this.isRequiredField,
            labelIsRequired: this.labelIsRequired,
            onFieldEdit: this.onFieldEdit,
            disabled: inactiveForm || !canEdit,
            onKeyPress: this.handleKeyPress
          }

          return (
            <ValidatorForm
              data-testid="customer-form"
              ref={r => {
                // initial isFormValue value
                if (!this.firstRef) {
                  this.firstRef = true
                  this.handleFormChange()
                }
                this.form = r
              }}
              debounceTime={DEBOUNCE_TIME}
              noValidate="novalidate"
              onChange={this.handleFormChange}
              onSubmit={() => this.submitWrapperCheck()}
              onError={error => {
                setAnyFormError(true)
              }}
            >
              {this.props.children(
                <Grid
                  container
                  spacing={2}
                  classes={{
                    root: inactiveForm ? classes.inactiveForm : undefined
                  }}
                  className={cx(classes.customerForm, {
                    [classes.disabledForm]: disableFields
                  })}
                >
                  <Fieldset
                    activeFieldset={fieldset}
                    fieldsets={NON_PUBLIC_FIELDSET_COLLECTION}
                  >
                    <Grid item xs={12} sm={showAlternatePhoneNumber ? 6 : 12}>
                      <AccessControl>
                        {({ isMosaicSupport }) => {
                          const isEditCustomerFlagEnabled =
                            editCustomerFlag(ldFlags)

                          const isEditable =
                            CustomerHelper.isPhoneFieldEditable({
                              canEdit,
                              disableFields,
                              isEditingCustomer,
                              isMosaicSupport,
                              isEditCustomerFlagEnabled
                            })
                          const isLandline =
                            phoneErrorType === errorKeys.phoneNumberIsLandline
                          const landlineNumberFlagEnabled =
                            landlineNumberEnabled(ldFlags)

                          return (
                            <PrequalificationRouteContext.Consumer>
                              {({ isSwiftlinks }) => {
                                const landlineErrorWithLandlineFlagEnabled =
                                  landlineNumberFlagEnabled && isLandline

                                return (
                                  <PhoneNumberField
                                    id={id}
                                    lockedFields={lockedFields}
                                    inputProps={inputProps}
                                    canEdit={isEditable}
                                    classes={classes}
                                    dataTest="customer-phone"
                                    phoneNumber={phoneNumber}
                                    showAlternatePhoneNumber={
                                      showAlternatePhoneNumber
                                    }
                                    onFieldEdit={this.onFieldEdit}
                                    getValidatorsRequired={
                                      this.getValidatorsRequired
                                    }
                                    getErrorMessagesRequired={
                                      this.getErrorMessagesRequiredWithL10n
                                    }
                                    error={
                                      landlineErrorWithLandlineFlagEnabled
                                        ? this.displayLandlineWarning()
                                        : CustomerHelper.phoneErrorMessage(
                                            phoneErrorType,
                                            phoneNumber
                                          )
                                    }
                                    toggleAlternatePhoneNumber={
                                      this.toggleAlternatePhoneNumber
                                    }
                                    onKeyPress={this.handleKeyPress}
                                  />
                                )
                              }}
                            </PrequalificationRouteContext.Consumer>
                          )
                        }}
                      </AccessControl>
                    </Grid>
                  </Fieldset>
                  {showAlternatePhoneNumber && (
                    <Grid item xs={12} sm={6}>
                      <NumberFormat
                        id={customerFields.phoneNumberAlt}
                        customInput={TextValidator}
                        className={
                          lockedFields[customerFields.phoneNumber] &&
                          classes.alternativePhoneNumberExtraMargin
                        }
                        format={fieldMasks.phoneNumber}
                        mask="_"
                        type="tel"
                        fullWidth
                        label={l10n.customer.phoneNumberAlternate}
                        onChange={this.onFieldEdit(
                          customerFields.phoneNumberAlt
                        )}
                        name={customerFields.phoneNumberAlt}
                        value={phoneNumberAlt1 || EMPTY_STRING}
                        placeholder={placeholders.phoneNumber}
                        disabled={disableFields}
                        autoComplete={browserAutocompleteKeys.telNational}
                        validators={[
                          ...this.getValidatorsRequired(
                            customerFields.phoneNumberAlt
                          ),
                          ...PHONE_NUMBER_VALIDATORS
                        ]}
                        errorMessages={[
                          ...this.getErrorMessagesRequiredWithL10n(
                            customerFields.phoneNumberAlt,
                            l10n
                          ),
                          l10n.customer.phoneNumberErrorMessage,
                          l10n.customer.phoneNumberMinLength
                        ]}
                        error={!!alternatePhoneError}
                        helperText={(alternatePhoneError || {}).errorMessage}
                        InputProps={{ ...inputProps }}
                      />
                      {canEdit && (
                        <AlternatePhoneNumber
                          handleClick={this.toggleAlternatePhoneNumber}
                          classes={classes}
                        />
                      )}
                    </Grid>
                  )}
                  <AccessControl>
                    {({ userType }) => {
                      const solarLoanType = isSolarLoanType({
                        userType,
                        loanType: customerLoanType
                      })
                      const solarLoanTypeMode =
                        solarLoanType &&
                        solarIntegratorPrequalificationEnabled(ldFlags)
                      const customerNameFieldColumnsCount =
                        singleDobFieldFlagEnabled(ldFlags) &&
                        fieldset === FIELDSET_PUBLIC_APPLICATION &&
                        !solarLoanTypeMode
                          ? 6
                          : 12
                      const customerNameFieldProps = {
                        fieldValues: solarLoanTypeMode
                          ? { firstName, middleName, lastName }
                          : { firstName, lastName },
                        fieldNames: solarLoanTypeMode
                          ? {
                              firstName: customerFields.firstName,
                              middleName: customerFields.middleName,
                              lastName: customerFields.lastName
                            }
                          : {
                              firstName: customerFields.firstName,
                              lastName: customerFields.lastName
                            },
                        fieldLabels: solarLoanTypeMode
                          ? {
                              firstName: l10n.customer.firstName,
                              middleName: l10n.customer.middleName,
                              lastName: l10n.customer.lastName
                            }
                          : {
                              firstName: l10n.customer.firstName,
                              lastName: l10n.customer.lastName
                            },
                        fieldAutocompleteKeys: solarLoanTypeMode
                          ? {
                              firstName: browserAutocompleteKeys.givenName,
                              middleName:
                                browserAutocompleteKeys.additionalName,
                              lastName: browserAutocompleteKeys.familyName
                            }
                          : {
                              firstName: browserAutocompleteKeys.givenName,
                              lastName: browserAutocompleteKeys.familyName
                            }
                      }

                      return !canEdit &&
                        fieldset !== FIELDSET_PUBLIC_APPLICATION ? (
                        <Grid item xs={12}>
                          <CustomerFullName
                            firstName={firstName}
                            lastName={lastName}
                            inputProps={{ ...inputProps }}
                          />
                        </Grid>
                      ) : (
                        <Grid
                          container
                          item
                          md={customerNameFieldColumnsCount}
                          className={cx({
                            [classes.singleInputFullName]:
                              fieldset !== FIELDSET_PUBLIC_APPLICATION,
                            [classes.singleInputFullNamePublicApplication]:
                              fieldset === FIELDSET_PUBLIC_APPLICATION,
                            [classes.middleNameVisible]: solarLoanTypeMode
                          })}
                        >
                          <LockableField
                            {...customerNameFieldProps}
                            unifiedLabel={l10n.customer.fullName}
                            unifyWhenLocked
                            fieldInputProps={buildFieldInputProps(
                              canEdit,
                              inactiveForm,
                              fieldset
                            )}
                            onFieldEdit={this.onFieldEdit}
                            disabled={!canEdit}
                            parentClasses={classes}
                            validators={
                              !customerVerified
                                ? EMPTY_ARRAY
                                : [
                                    ...NO_SPECIAL_CHARACTERS_VALIDATORS,
                                    ...COMPOUND_NAME_VALIDATORS,
                                    ...NAME_LENGTH
                                  ]
                            }
                            errorMessages={[
                              l10n.customer.errorMessages.noSpecialCharacters,
                              l10n.customer.errorMessages.noCompoundNames,
                              l10n.customer.errorMessages.nameMinLength
                            ]}
                            requiredFields={
                              fieldsets[fieldset]
                                ? !customerVerified
                                  ? EMPTY_ARRAY
                                  : fieldsets[fieldset].requiredFields
                                : EMPTY_ARRAY
                            }
                            InputLabelProps={{
                              required: this.labelIsRequired()
                            }}
                            InputProps={{ onKeyPress: this.handleKeyPress }}
                            fieldset={fieldset}
                            fieldHelperTexts={{
                              firstName:
                                fieldset === FIELDSET_PUBLIC_APPLICATION
                                  ? l10n.prequalificationForm
                                      .firstNameHelperText
                                  : undefined
                            }}
                            unlockedGridItemProps={getUnlockedGridItemProps(
                              fieldset,
                              solarLoanTypeMode
                            )}
                          />
                        </Grid>
                      )
                    }}
                  </AccessControl>

                  <Fieldset
                    activeFieldset={fieldset}
                    fieldsets={NON_PUBLIC_FIELDSET_COLLECTION}
                  >
                    <CustomerEmail
                      {...baseFieldProps}
                      emailAddressVerified={emailAddressVerified}
                      canEdit={canEdit || !disableFields}
                      onFieldEdit={this.onFieldEdit}
                      onKeyPress={this.handleKeyPress}
                      email={email}
                      helperText={CustomerHelper.emailErrorMessage(
                        emailErrorType,
                        email
                      )}
                      showError={!isEmpty(emailErrorType)}
                      required={this.isRequiredField(customerFields.email)}
                      inputProps={{
                        maxLength:
                          customerFieldProperties.maxLengths[
                            customerFields.email
                          ]
                      }}
                    />
                  </Fieldset>

                  <Fieldset
                    activeFieldset={fieldset}
                    fieldsets={[FIELDSET_APPLY, FIELDSET_PUBLIC_APPLICATION]}
                  >
                    <Grid
                      item
                      xs={12}
                      md={fieldset === FIELDSET_PUBLIC_APPLICATION ? 6 : 12}
                    >
                      <PersonalIncomeField
                        {...baseFieldProps}
                        onClickReadMorePersonalIncome={
                          this.onClickReadMorePersonalIncome
                        }
                        readMoreClicked={readMoreClicked}
                        placeholder={
                          fieldset === FIELDSET_PUBLIC_APPLICATION
                            ? l10n.prequalificationForm.incomePlaceholder
                            : undefined
                        }
                        incomeAlerts={incomeAlerts}
                        singleDobField={singleDobFieldFlagEnabled(ldFlags)}
                      />
                    </Grid>
                    <AccessControl>
                      {({ userType }) => {
                        const solarLoanType = isSolarLoanType({
                          userType,
                          loanType: customerLoanType
                        })
                        const otherHouseholdIncomeFlagOn =
                          householdIncomeOnHomeP2XApplications(ldFlags)
                        const showOtherHouseholdIncome =
                          solarLoanType || otherHouseholdIncomeFlagOn
                        return (
                          showOtherHouseholdIncome && (
                            <Grid
                              item
                              xs={12}
                              md={
                                fieldset === FIELDSET_PUBLIC_APPLICATION
                                  ? 6
                                  : 12
                              }
                            >
                              <OtherHouseholdIncomeField
                                {...baseFieldProps}
                                placeholder={
                                  fieldset === FIELDSET_PUBLIC_APPLICATION
                                    ? l10n.prequalificationForm
                                        .incomePlaceholder
                                    : undefined
                                }
                                singleDobField={singleDobFieldFlagEnabled(
                                  ldFlags
                                )}
                              />
                            </Grid>
                          )
                        )
                      }}
                    </AccessControl>

                    <Grid item xs={12} md={6} className={classes.ssnField}>
                      <SsnField
                        {...baseFieldProps}
                        singleDobField={singleDobFieldFlagEnabled(ldFlags)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={
                        fieldset === FIELDSET_PUBLIC_APPLICATION &&
                        !singleDobFieldFlagEnabled(ldFlags)
                          ? 12
                          : 6
                      }
                    >
                      <DobFields
                        {...baseFieldProps}
                        showSingleField={singleDobFieldFlagEnabled(ldFlags)}
                        dobDay={dobDay}
                        dobMonth={dobMonth}
                        dobYear={dobYear}
                        dobFull={dobFull}
                        dobAlert={this.state.dobAlert}
                      />
                    </Grid>
                  </Fieldset>

                  {fieldset === FIELDSET_PUBLIC_APPLICATION && (
                    <AddressBody
                      classes={classes}
                      inactiveForm={inactiveForm}
                    />
                  )}

                  <Address
                    address={addressMap.get(projectAddressType)}
                    addressLabel={l10n.customer.projectAddress}
                    addressType={projectAddressType}
                    canEdit={canEdit || !disableFields}
                    addressIsModified={addressIsModified}
                    getAddressValue={this.getAddressValue}
                    getErrorMessagesRequired={
                      this.getErrorMessagesRequiredWithL10n
                    }
                    getValidatorsRequired={this.getValidatorsRequired}
                    inputProps={inputProps}
                    isRequiredField={this.isRequiredField}
                    labelIsRequired={this.labelIsRequired}
                    onAddressMapEdit={this.onAddressMapEdit}
                  />
                  {primaryAddressError && (
                    <FieldErrorMessage
                      classes={classes}
                      errorMessage={primaryAddressError}
                    />
                  )}
                  {projectAddressType !== addressTypes.unknown && (
                    <Grid item xs={12}>
                      <AddressTypeDropdown
                        canEdit={canEdit || !disableFields}
                        onSelectChange={this.onAddressTypeChange}
                        selection={projectAddressType}
                      />
                    </Grid>
                  )}

                  {isNotPrimaryOrUnknownAddressType(projectAddressType) && (
                    <Address
                      address={addressMap.get(addressTypes.primary)}
                      addressLabel={l10n.customer.primaryAddress}
                      addressType={addressTypes.primary}
                      canEdit={canEdit || !disableFields}
                      addressIsModified={addressIsModified}
                      getAddressValue={this.getAddressValue}
                      getErrorMessagesRequired={
                        this.getErrorMessagesRequiredWithL10n
                      }
                      getValidatorsRequired={this.getValidatorsRequired}
                      inputProps={inputProps}
                      isRequiredField={this.isRequiredField}
                      labelIsRequired={this.labelIsRequired}
                      onAddressMapEdit={this.onAddressMapEdit}
                    />
                  )}

                  {secondHomeOrRentalError && (
                    <FieldErrorMessage
                      classes={classes}
                      errorMessage={secondHomeOrRentalError}
                    />
                  )}
                  <Grid item xs={12}>
                    <AlternativeAddressCheckbox
                      classes={classes}
                      disabled={disableFields}
                      handleChange={this.toggleMailingAddress}
                      checked={showMailingAddress}
                    />
                  </Grid>

                  {showMailingAddress && (
                    <Address
                      address={addressMap.get(addressTypes.mailing)}
                      addressLabel={l10n.customer.mailingAddress}
                      addressType={addressTypes.mailing}
                      canEdit={canEdit || !disableFields}
                      getAddressValue={this.getAddressValue}
                      addressIsModified={addressIsModified}
                      getErrorMessagesRequired={
                        this.getErrorMessagesRequiredWithL10n
                      }
                      getValidatorsRequired={this.getValidatorsRequired}
                      inputProps={inputProps}
                      isRequiredField={this.isRequiredField}
                      labelIsRequired={this.labelIsRequired}
                      onAddressMapEdit={this.onAddressMapEdit}
                    />
                  )}
                  {showMailingAddress && mailingAddressError && (
                    <FieldErrorMessage
                      classes={classes}
                      errorMessage={mailingAddressError}
                    />
                  )}

                  {fieldset === FIELDSET_PUBLIC_APPLICATION &&
                    !customerIdParamExists && (
                      <>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.assignToHelpText}
                        >
                          <Typography
                            variant="body1"
                            className={classes.bodyTextNormal}
                          >
                            {l10n.prequalificationForm.assignToHelpText}
                          </Typography>
                          <ReferredEmailField
                            {...baseFieldProps}
                            emailAddressVerified={emailAddressVerified}
                            canEdit={canEdit || !disableFields}
                            onFieldEdit={this.onFieldEdit}
                            email={assignedTo}
                            helperText={CustomerHelper.emailErrorMessage(
                              emailErrorType
                            )}
                            inputProps={{
                              maxLength:
                                customerFieldProperties.maxLengths[
                                  customerFields.email
                                ]
                            }}
                          />
                        </Grid>
                      </>
                    )}

                  <AccessControl allowedUserTypes={[userTypes.installer]}>
                    <Fieldset
                      activeFieldset={fieldset}
                      fieldset={FIELDSET_ABOUT}
                    >
                      <Grid item xs={6}>
                        <TextValidator
                          id={customerFields.notes}
                          fullWidth
                          multiline
                          rowsMax={MAX_ROWS_NOTES}
                          label={l10n.customer.notes}
                          onChange={this.onFieldEdit(customerFields.notes)}
                          name={customerFields.notes}
                          value={notes || EMPTY_STRING}
                          disabled={disableFields}
                          validators={this.getValidatorsRequired(
                            customerFields.notes
                          )}
                          errorMessages={this.getErrorMessagesRequiredWithL10n(
                            customerFields.notes,
                            l10n
                          )}
                          required={this.isRequiredField(customerFields.notes)}
                          InputProps={{ ...inputProps }}
                          InputLabelProps={!canEdit ? { shrink: true } : {}}
                        />
                      </Grid>
                    </Fieldset>
                    {notesError && (
                      <FieldErrorMessage
                        classes={classes}
                        errorMessage={notesError}
                      />
                    )}
                  </AccessControl>

                  <Fieldset activeFieldset={fieldset} fieldset={FIELDSET_ABOUT}>
                    <AccessControl
                      allowedUserTypes={[userTypes.installer]}
                      allowedUserRoles={[
                        userRoles.installer.owner,
                        userRoles.installer.salesmgr,
                        userRoles.installer.admin
                      ]}
                    >
                      {({
                        hasAdminRole,
                        hasSalesManagerRole,
                        hasOwnerRole
                      }) => {
                        if (
                          hasAdminRole ||
                          hasOwnerRole ||
                          hasSalesManagerRole
                        ) {
                          return (
                            <Grid item xs={6} className={classes.assigned}>
                              <Query
                                query={CustomerPartnerQuery}
                                fetchPolicy="network-only"
                              >
                                {({ loading, error, data }) => {
                                  const assignableUsers =
                                    addFullNameToUsersInList(
                                      get(
                                        data,
                                        'channelPartner.partnerLogins',
                                        []
                                      )
                                    )

                                  return (
                                    <UserContext.Consumer>
                                      {userContext => {
                                        const me = get(userContext, 'me', {
                                          id: EMPTY_STRING,
                                          firstName: EMPTY_STRING,
                                          lastName: EMPTY_STRING
                                        })
                                        const { assignedSalesperson } =
                                          this.state
                                        const assignedTo: AutoCompleteComboBoxOption =
                                          CustomerHelper.assignedTo(
                                            salesperson,
                                            me,
                                            assignedSalesperson
                                          )

                                        return (
                                          <AutoCompleteComboBox
                                            data-testid={testIds.assignedTo}
                                            id={customerFields.salespersonId}
                                            label={l10n.customer.assignedTo}
                                            disabled={disableFields}
                                            isAutoFocus={false}
                                            displayKey="fullName"
                                            options={assignableUsers}
                                            name={customerFields.salespersonId}
                                            selectedOption={assignedTo}
                                            onChangeDropdown={(
                                              event,
                                              value
                                            ) => {
                                              this.onAdminChange(event, value)
                                            }}
                                            helperText={
                                              l10n.customer.assignedToHelperText
                                            }
                                          />
                                        )
                                      }}
                                    </UserContext.Consumer>
                                  )
                                }}
                              </Query>
                            </Grid>
                          )
                        }
                        return null
                      }}
                    </AccessControl>
                  </Fieldset>
                </Grid>,
                {
                  submit: this.form && this.form.submit,
                  reset: this.setInitialValues
                }
              )}
            </ValidatorForm>
          )
        }}
      </TranslationsContext.Consumer>
    )
  }
}

export default flow(
  withRouter,
  withLaunchDarkly,
  withApolloConsumer
)(CustomerFormView)
