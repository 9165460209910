// @flow
import React from 'react'
import GenericErrorPage from 'components/shared/GenericErrorPage'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import l10n from 'properties/translations'
import { isBorrower } from 'util/CustomerHelper'
import {
  subdomainSupportsWeblinks,
  shouldSeeWeblinksPartnerNotFoundErrorPage,
  getAllowDenyHostnamesConfig,
  noHostnamesAllowed
} from 'util/PrequalificationHelper'
import errorTypes, { type ErrorType } from 'constants/enums/errorTypes'

type ErrorHandlingPageProps = {
  userId: string,
  showHeader: boolean,
  me: any,
  toggleHeader: () => void,
  ldFlags: any,
  errorType?: ErrorType
}

const getTitle = (errorType: ErrorType) => {
  switch (errorType) {
    case errorTypes.creditApplicationExpired:
      return l10n.prequalificationForm.creditApplicationExpiredError.title
    case errorTypes.internalServerError:
    case errorTypes.serverError:
      return l10n.serverError.title
    default:
      return l10n.notFound.partner.title
  }
}

const getDescriptionOverviewText = (errorType: ErrorType) => {
  switch (errorType) {
    case errorTypes.creditApplicationExpired:
      return l10n.prequalificationForm.creditApplicationExpiredError.description
    case errorTypes.internalServerError:
    case errorTypes.serverError:
      return l10n.serverError.descriptionLn1
    default:
      return l10n.notFound.partner.descriptionLn1
  }
}

const ErrorHandlingPage = ({
  me,
  ldFlags,
  ...props
}: ErrorHandlingPageProps) => {
  const allowDenyHostnamesConfig = getAllowDenyHostnamesConfig(ldFlags)
  const weblinksSubdomain = subdomainSupportsWeblinks()
  const { userId, userType } = me || {}
  const { errorType } = props

  return !noHostnamesAllowed(allowDenyHostnamesConfig) &&
    weblinksSubdomain &&
    shouldSeeWeblinksPartnerNotFoundErrorPage(userType) ? (
    <GenericErrorPage
      {...props}
      userId={userId || props.userId}
      title={getTitle(errorType)}
      descriptionOverviewText={getDescriptionOverviewText(errorType)}
      descriptionDetailText={!errorType && l10n.notFound.partner.descriptionLn2}
      showBackButton={false}
    />
  ) : (
    <GenericErrorPage
      {...props}
      userId={userId || props.userId}
      isBorrower={isBorrower(me)}
    />
  )
}

export default withLaunchDarkly(ErrorHandlingPage)
