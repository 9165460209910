import { ApolloClient } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { ApolloLink, split, Observable, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { type GraphQLError } from '@apollo'
import { get, head } from 'lodash'
import * as AnalyticsHelper from 'util/AnalyticsHelper'
import { eventTypes } from 'constants/enums/analyticsEventTypes'
import {
  destroyUserSession,
  getUserAccessToken,
  getUserRefreshToken,
  storeUserTokens
} from 'util/SessionHelper'
import { getEnvironment } from 'util/EnvironmentHelper'
import {
  writeMessageToCache,
  graphQLErrorsAreHandledByComponent
} from 'util/MessageHelper'
import { getDeviceFingerprint } from 'util/DeviceHelper'
import { getAccessMode } from 'util/AccessModeHelper'
import { userAPI, guestAPI, EMPTY_STRING } from 'properties/properties'
import ValidateRefreshToken from 'queries/ValidateRefreshToken.graphql'
import errorTypes from 'constants/enums/errorTypes'
import { errorHasGraphQLErrorType } from 'util/ErrorHelper'
import history from '../history'
import genericErrorMessages from './genericErrorMessages.json'
import { quote, message } from 'screens/quote/QuoteStore'
import { setup } from 'screens/setup/SetupStore'
import { getUUIDFromPath } from 'util/AuthHelper'

// Operation names (i.e. the arbitrary graphql query/mutation alias `query OperationName {`
// that should ALWAYS hit the guest schema even if the user is logged in.)
// Please use PascalCase for these.
const forcedGuestSchemaOperations = [
  'ValidateRefreshToken',
  'GetPasswordResetIdentity',
  'ResetPassword',
  'VerifyAndAuthenticateGuest',
  'ChannelPartnerGuest',
  'CreateAndAuthenticateCustomer',
  'SendResetMessaging',
  'SignContractGuest'
]

export default function Client() {
  const httpGuestLink = new HttpLink({ uri: guestAPI })

  const httpUserLink = new HttpLink({ uri: userAPI, credentials: 'omit' })

  const devToolConfig = {
    connectToDevTools: getEnvironment() === 'local'
  }

  const withToken = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getUserAccessToken()

    // get current mode from session storage
    const accessMode = getAccessMode()

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        auth: token,
        accessMode
      }
    }
  })

  const cache = new InMemoryCache({
    addTypename: true,
    typePolicies: {
      Query: {
        fields: {
          quote: {
            read() {
              return quote()
            }
          },
          setup: {
            read() {
              return setup()
            }
          },
          message: {
            read() {
              return message()
            }
          }
        }
      }
    }
  })

  const guestClient = new ApolloClient({
    link: ApolloLink.from([httpGuestLink]),
    cache,
    connectToDevTools: true,
    ...devToolConfig
  })

  const logout = () => {
    destroyUserSession()

    AnalyticsHelper.identifyGuest()
    history.push('/login', {
      from: history.location
    })
  }

  const verifyRefreshToken = async (refreshToken, deviceFingerprint) => {
    try {
      const result = await guestClient.mutate({
        mutation: ValidateRefreshToken,
        variables: {
          refreshToken,
          deviceFingerprint
        }
      })
      const { accessToken, userId, userType } = get(
        result,
        'data.validateRefreshToken',
        {}
      )
      storeUserTokens(accessToken, refreshToken)

      if (accessToken) {
        AnalyticsHelper.identify(userType, userId)
        AnalyticsHelper.track(eventTypes.userRefreshedAccessToken, {
          deviceFingerprint
        })
      }

      return accessToken
    } catch (e) {
      logout()
      return null
    }
  }

  // OnError can return either Observable (if retrying request) or void
  /* eslint-disable consistent-return */
  const resetToken = onError(
    ({
      graphQLErrors,
      networkError: { statusCode } = {},
      operation,
      forward,
      response
    }) => {
      if (statusCode === 401) {
        // isLinkSharing condition means that we will not verifyRefreshToken on linkshare pages as listed in LinkSharingRoutes.
        // Those pages already verify (LinkSharingFrame/componentDidMount).
        const isLinkSharing =
          window && getUUIDFromPath(window.location.pathname)
        const refreshToken = getUserRefreshToken({ allowFromPath: false })

        if (refreshToken && !isLinkSharing) {
          return new Observable(observer => {
            getDeviceFingerprint().then(deviceFingerprint => {
              verifyRefreshToken(refreshToken, deviceFingerprint)
                .then(accessToken => {
                  if (!accessToken) {
                    // EX-3425: Return JSON so try/catch will catch an error
                    return { error: true }
                  }
                  operation.setContext(({ headers = {} }) => ({
                    headers: {
                      ...headers,
                      auth: accessToken
                    }
                  }))
                  return true
                })
                .then(refreshSuccess => {
                  if (refreshSuccess) {
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer)
                    }

                    forward(operation).subscribe(subscriber)
                  }
                })
            })
          })
        }
        logout()
      }

      // server errors
      if (statusCode >= 500) {
        const text =
          genericErrorMessages[statusCode] || genericErrorMessages['500']

        writeMessageToCache({ text, cache })

        return
      }

      // @todo: add a handler for recoverable errors, use: graphQLErrors parameter
      if (graphQLErrors && !graphQLErrorsAreHandledByComponent(graphQLErrors)) {
        const error: GraphQLError = head(graphQLErrors)
        writeMessageToCache({
          text: genericErrorMessages['400'],
          errMsg: get(error, 'message', EMPTY_STRING),
          errorType: get(
            error,
            'extensions.type',
            get(error, 'extensions.key')
          ),
          cache
        })
      }

      if (errorHasGraphQLErrorType({ graphQLErrors }, errorTypes.notFound)) {
        response.data = {
          customers: []
        }
      }
    }
  )
  /* eslint-enable consistent-return */

  const authLink = withToken.concat(resetToken)

  const httpLink = split(
    context => {
      if (forcedGuestSchemaOperations.includes(context.operationName))
        return false
      const token = getUserAccessToken()
      return Boolean(token)
    },
    authLink.concat(httpUserLink),
    httpGuestLink
  )

  /* eslint-disable no-console */
  const client = new ApolloClient({
    link: httpLink,
    cache,
    addTypename: true,

    onError: ({ operation, response, graphQLErrors, networkError }) => {
      if (operation) {
        console.log('operation')
        console.log(operation)
      }
      if (response) {
        console.log('response')
        console.log(response)
      }
      if (graphQLErrors) {
        // TODO what do with these errors?
        console.log('graphQLErrrors')
        console.log(graphQLErrors)
      }
      if (networkError) {
        // TODO log error
        console.log('TODO: report network error')
        console.log(networkError)
      }
    },
    connectToDevTools: true,
    ...devToolConfig
  })

  /* eslint-enable no-console */

  return { client, guestClient }
}
