import { colors } from 'themes/default'

export default theme => ({
  setUpPayment: {
    color: colors.textSnackBranch,
    '& .MuiButton-label': {
      fontSize: theme.typography.fontSize + 2
    }
  },
  messageText: {
    fontSize: theme.typography.fontSize + 2
  },
  branchToastMessage: {
    wordBreak: 'break-word'
  }
})
