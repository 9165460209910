import React, { useContext, useState, useMemo } from 'react'
import ReactSessionApi from 'react-session-api'
import { isEmpty, head, get, flow, reject } from 'lodash'
import { withRouter } from 'react-router-dom'
import { Mutation } from '@apollo/client/react/components'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Client from 'gql/clientNew'
import {
  isEmailValid,
  isPhoneNumberValid,
  EMPTY_STRING
} from 'properties/properties'
import {
  getEnteredCustomer,
  setEnteredCustomer,
  setCustomerIdInStorage,
  updateQuote,
  trackMutation,
  isDeprecatedEndpointError,
  getPhoneInformation
} from 'util/PrequalificationHelper'
import type { UserAuthModel, AgreeAndContinueFormProps } from 'util/TypesHelper'
import MonitoringHelper from 'util/MonitoringHelper'
import { track } from 'util/AnalyticsHelper'
import { landlineNumberEnabled } from 'util/FeatureHelper'
import { getServerErrorMessageObject } from 'util/CustomerHelper'
import { getPhoneAndEmailType } from 'util/PrequalificationHelper'
import UserContext from 'util/UserContext'
import PrequalificationFormContext from 'util/PrequalificationFormContext'
import PrequalificationRouteContext from 'util/PrequalificationRouteContext'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import localStorageKeys from 'constants/enums/localStorageKeys'
import { eventTypes } from 'constants/enums/analyticsEventTypes'
import createCustomerSources from 'constants/enums/createCustomerSources'
import errorKeys from 'constants/enums/errorKeys'
import userTypes from 'constants/enums/userTypes'
import errorTypes from 'constants/enums/errorTypes'
import customerFields from 'constants/enums/customerFields'
import Disclosures from 'components/shared/DeviceVerification/Disclosures'
import ValidatePhoneAndEmailGraphql from 'screens/customer/queries/ValidatePhoneAndEmail.graphql'
import GuestCreateCustomer from 'screens/apply/queries/GuestCreateCustomer.graphql.js'
import CreateCustomer from 'screens/apply/queries/CreateCustomer.graphql.js'
import ContinueButton from './ContinueButton'
import EmailAndPhoneInputs from './EmailAndPhoneInputs'
import ErrorComponent from './ErrorComponent'
import FormDescription from './FormDescription'
import styles from './AgreeAndContinueForm.styles'
import { channelPartnerHasBlockOpportunityCreationFlag } from 'screens/apply/ChannelPartnerHelper'
import Message from 'components/shared/Message'
import l10n from 'properties/translations'

const useStyles = makeStyles(styles)
let isVerifyingUser
const setIsVerifyingUser = value => {
  isVerifyingUser = value
}

const AgreeAndContinueForm = ({
  ldFlags,
  client,
  location: { search },
  match,
  deviceFingerprint,
  onDeviceVerificationStarted,
  onDeviceVerificationFinished,
  onDeviceVerificationError,
  onAgreeAndContinue,
  currentCustomerId,
  applicationSubmitting,
  callConsentToContact,
  clickedContinue,
  setContactConsent,
  setCustomerId,
  isEstimateFlow,
  projects,
  reCaptchaToken,
  setDeprecatedEndpointError,
  formValidationInProgress
}: AgreeAndContinueFormProps) => {
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const aggregatorCode = searchParams.get('aggregatorCode')
  const { guestClient } = new Client()
  const classes = useStyles()

  const landlineNumberFlagEnabled = landlineNumberEnabled(ldFlags)

  const { isSwiftlinks } = useContext(PrequalificationRouteContext)
  const { me, channelPartner } = useContext(UserContext)
  const { customerVerified, customer, customerLoanType } = useContext(
    PrequalificationFormContext
  )
  const userType = get(me, 'userType')
  const userId = get(me, 'userId')
  const partnerCode = get(channelPartner, 'hostName')

  const [trackedMutation, setTrackedMutation] = useState(EMPTY_STRING)
  const [email, setEmail] = useState(EMPTY_STRING)
  const [phoneNumber, setPhoneNumber] = useState(EMPTY_STRING)
  const [serverErrors, setServerErrors] = useState(EMPTY_STRING)
  const [isLandlineNumber, setIsLandlineNumber] = useState(false)
  const [recaptchaError, setRecaptchaError] = useState(null)
  const [verificationFailed, setVerificationFailed] = useState(EMPTY_STRING)

  const customerExists = Boolean(customer)
  const customerIdParamExists = match.params.customerId
  const enteredCustomer = getEnteredCustomer()
  const isEstimateOrPrequalifyFlow =
    !isSwiftlinks && !customerIdParamExists && !isEmpty(enteredCustomer)
  const swiftlinksFlowWithDeviceFields =
    isSwiftlinks && !isEmpty(enteredCustomer)

  const updatedEmail = email || customer.email
  const updatedPhoneNumber = phoneNumber || customer.phoneNumber

  const areDeviceVerificationFieldsDisabled = verificationFailed => {
    if (verificationFailed) return false
    if (applicationSubmitting) return true
    if (isVerifyingUser) return true
    if (isEstimateOrPrequalifyFlow) return true
    if (swiftlinksFlowWithDeviceFields) return true
    return customerExists
  }
  const fieldDisabled = areDeviceVerificationFieldsDisabled(verificationFailed)

  if (verificationFailed && !recaptchaError) {
    const isRecaptchaError = verificationFailed.some(
      error => get(error, 'extensions.type') === errorTypes.invalidCaptcha
    )
    if (isRecaptchaError) setRecaptchaError(true)
  }

  const handleErrorForChildren = error => {
    if (error) {
      setServerErrors(error.graphQLErrors)
      setVerificationFailed(error.graphQLErrors)
    }
  }

  const updateServerError = error => setServerErrors(error.graphQLErrors)

  const serverErrorMessages = getServerErrorMessageObject(serverErrors)

  const deprecatedEndpointError = isDeprecatedEndpointError(serverErrors)
  if (deprecatedEndpointError) {
    setDeprecatedEndpointError(deprecatedEndpointError.message)
  }

  const deviceVerificationMutation = () => {
    return isSwiftlinks
      ? trackMutation(GuestCreateCustomer, trackedMutation, setTrackedMutation)
      : trackMutation(CreateCustomer, trackedMutation, setTrackedMutation)
  }
  const mutationToCall = deviceVerificationMutation()

  const variablesToSend = (variables: Object, reCaptcha: string | null) => {
    if (!isSwiftlinks) {
      return {
        request: {
          source: createCustomerSources.pos,
          phoneNumber: variables.phoneNumber.toString(),
          emailAddress: variables.email,
          salespersonId: userId,
          loanType: customerLoanType
        }
      }
    }

    const baseRequest = {
      deviceFingerprint,
      partnerCode,
      aggregatorCode: aggregatorCode || undefined,
      phoneNumber: variables.phoneNumber.toString(),
      emailAddress: variables.email
    }

    return {
      request: baseRequest,
      captchaToken: reCaptcha
    }
  }

  const genericHandler = async (
    variables: Object,
    mutationFn: Function,
    customerId: string
  ) => {
    let recaptchaToSend = reCaptchaToken
    setIsVerifyingUser(true)

    if (recaptchaError && window.grecaptcha) {
      recaptchaToSend = await window.grecaptcha.execute()
    }

    const vars = variablesToSend(variables, recaptchaToSend)

    let result
    try {
      result = await mutationFn({
        variables: vars
      })
    } catch (error) {
      MonitoringHelper.manuallyReportError(error)

      return
    }

    setRecaptchaError(null)

    if (typeof result === 'undefined' || result.errors) {
      onDeviceVerificationError()
      return
    }

    if (isSwiftlinks) {
      const userAuthInfo: ?UserAuthModel = get(
        result,
        'data.createAndAuthenticateCustomer'
      )
      onDeviceVerificationFinished(userAuthInfo)
    } else {
      onAgreeAndContinue()
    }
    // To solve EX-5526, wrap this to a try to fix the issue when any of the below code thrown an exception, to force setIsVerifyingUser to false so the form is unlocked.
    // However, fundementally, the program should handle the excepion rather then thrown it. This is currently thrown exception when data doesn't exist.
    // This shouldn't be the case, the program should handle the case when data doesn't exist.
    // TODOS: EX-5527
    if (result.data) {
      customerId = isSwiftlinks
        ? get(result, 'data.createAndAuthenticateCustomer.userId')
        : get(result, 'data.createCustomer.id')

      // if an installer is logged in then the customer was already created, so we pass it's id to the ConsentToContact mutation)
      if (!isSwiftlinks && userType === userTypes.installer) {
        callConsentToContact(customerId)
        if (isEstimateFlow) {
          await updateQuote(client, customerId, projects)
        }
      } else {
        // for Weblinks flow there's no customerId because there's no logged user (it's just a borrower)
        callConsentToContact()
      }

      setCustomerId(customerId)
      setCustomerIdInStorage(customerId)
    }

    setEnteredCustomer(variables)
  }

  const createNewCustomer = async (
    variables: Object,
    mutationFn: Function,
    customerId: string,
    updateServerError: Function
  ) => {
    const isLandline = await getPhoneInformation(variables, setIsLandlineNumber)
    if (isLandline && !ReactSessionApi.get(localStorageKeys.allowedLandline)) {
      try {
        await guestClient.query({
          query: ValidatePhoneAndEmailGraphql,
          variables: {
            phoneNumber: variables.phoneNumber.toString(),
            emailAddress: variables.email
          }
        })
        genericHandler(variables, mutationFn, customerId)
      } catch (error) {
        MonitoringHelper.manuallyReportError(error)

        updateServerError(error)
        onDeviceVerificationError()

        return
      }
    } else {
      genericHandler(variables, mutationFn, customerId)
    }
  }

  const continueHandler = async (
    mutationFn: Function,
    variables: Object,
    updateServerError: Function
  ) => {
    let customerId

    if (!customerExists) {
      await createNewCustomer(
        variables,
        mutationFn,
        customerId,
        updateServerError
      )
    } else if (customer && !isSwiftlinks && userType === userTypes.installer) {
      callConsentToContact(customer.id)
    } else {
      callConsentToContact()
    }

    setIsVerifyingUser(false)
    track(eventTypes.prequalificationAgreeAndContinueClicked, {
      customerId,
      userType,
      isSwiftlinks,
      mutation: trackedMutation
    })
  }

  const agreeAndContinueHandler = (
    serverErrors,
    variables,
    mutationFn,
    updateServerError
  ) => {
    const isOptedOutNumber =
      serverErrors.length === 1 &&
      head(serverErrors).extensions.key === errorKeys.phoneNumberIsOptedOut

    const isAllowedLandline =
      ReactSessionApi.get(localStorageKeys.allowedLandline) &&
      isLandlineNumber &&
      serverErrors.length === 1 &&
      landlineNumberFlagEnabled

    if (isEmpty(serverErrors) || isAllowedLandline || isOptedOutNumber) {
      track(eventTypes.prequalificationSubmitClicked, {
        deviceFingerprint
      })
      onDeviceVerificationStarted(variables)
      continueHandler(mutationFn, variables, updateServerError)
    }
  }

  const onFieldEdit = name => input => {
    const value = getPhoneAndEmailType(name, input)
    const clearingErrors =
      !isEmpty(serverErrors) || !isEmpty(Object.values(serverErrors))
    const fieldValues = {
      [customerFields.email]: {
        setValue: value => setEmail(value)
      },
      [customerFields.phoneNumber]: {
        setValue: value => setPhoneNumber(value)
      }
    }

    const fieldRetainError = reject(serverErrors, error => {
      if (!isEmpty(serverErrors) && head(serverErrors).extensions) {
        const {
          extensions: { field }
        } = error

        switch (name) {
          case customerFields.phoneNumber:
            return field === name
          case customerFields.email:
            return field === customerFields.email
          case customerFields.emailAddress:
            return field === customerFields.email
          default:
            return false
        }
      }
    })
    if (fieldValues[name]) {
      fieldValues[name].setValue(value)
    }

    if (clearingErrors) {
      setServerErrors([])
    } else {
      setServerErrors(fieldRetainError)
    }
  }

  const hasBlockOpportunityCreationFlag: boolean =
    channelPartnerHasBlockOpportunityCreationFlag(channelPartner.flags)

  return (
    <Mutation mutation={mutationToCall} onError={handleErrorForChildren}>
      {mutationFn => {
        return (
          <>
            <EmailAndPhoneInputs
              email={email}
              phoneNumber={phoneNumber}
              serverErrors={serverErrors}
              onFieldEdit={onFieldEdit}
              fieldDisabled={fieldDisabled}
              isLandlineNumber={isLandlineNumber}
              agreeAndContinueHandler={agreeAndContinueHandler}
              updateServerError={updateServerError}
              mutationFn={mutationFn}
            />
            {customerVerified ? (
              <FormDescription />
            ) : (
              <>
                <Grid className={classes.disclosureBlock}>
                  <Disclosures classes={classes} />
                </Grid>
                <Grid
                  container
                  justify="center"
                  direction="column"
                  alignItems="center"
                  spacing={2}
                  className={classes.agreeAndContinueButton}
                >
                  <ContinueButton
                    onConsentToContact={() => {
                      setContactConsent(true)
                    }}
                    currentCustomerId={currentCustomerId}
                    disabled={
                      !isPhoneNumberValid(updatedPhoneNumber) ||
                      !isEmailValid(updatedEmail) ||
                      formValidationInProgress ||
                      hasBlockOpportunityCreationFlag
                    }
                    onClick={() => {
                      agreeAndContinueHandler(
                        serverErrors,
                        { phoneNumber, email },
                        mutationFn,
                        updateServerError
                      )
                    }}
                    showLoading={clickedContinue && !recaptchaError}
                  />
                  <ErrorComponent
                    serverErrorMessages={serverErrorMessages}
                    recaptchaError={recaptchaError}
                  />
                  {hasBlockOpportunityCreationFlag && (
                    <Message text={l10n.snackbarPrequalDisabledTexts.message} />
                  )}
                </Grid>
              </>
            )}
          </>
        )
      }}
    </Mutation>
  )
}

export default flow(
  withRouter,
  withApolloConsumer,
  withLaunchDarkly
)(AgreeAndContinueForm)
