/* eslint-disable */
// @flow
import React, { useEffect, useState } from 'react'
import type { Node } from 'react'
import ScriptTag from 'react-script-tag'
import ReactDOM from 'react-dom'
import { Router, withRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { Provider } from 'unstated'
import { get, flow, isEmpty } from 'lodash'
import { HelmetProvider } from 'react-helmet-async'
import * as AnalyticsHelper from 'util/AnalyticsHelper'
import * as DeviceHelper from 'util/DeviceHelper'
import ScrollToTopOnPageNav from 'util/ScrollToTopOnPageNav'
import Loading from 'components/shared/Loading'
import createTheme, { colors } from 'themes/default'
import sessionStorageKeys from 'constants/enums/sessionStorageKeys'
import LaunchDarklyProvider from 'components/shared/LaunchDarklyProvider'
import WebsiteMonitoring from 'components/shared/WebsiteMonitoring'
import GenericErrorPage from 'components/shared/GenericErrorPage'
import l10n from 'properties/translations'
import themeOverrideKeys from 'constants/enums/themeOverrideKeys'
import Client from 'gql/clientNew'
import { makeSafeSessionStorage } from 'util/makeSafeStorage'
import {
  appendAppVersionCommitHashToHead,
  inP3xIframe,
  inIframe
} from 'util/EnvironmentHelper'
import { clearSessionOnAnyRefreshToken } from 'util/FeatureHelper'
import history from './history'
import './index.css'
import App from './App'
import GetClientIds from './queries/GetClientIds.graphql'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import ScreenTitle from 'components/shared/ScreenTitle'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import { getUUIDFromPath } from 'util/AuthHelper'
import { destroyUserAccessToken } from 'util/SessionHelper'

appendAppVersionCommitHashToHead()
const safeSessionStorage = makeSafeSessionStorage()
const { client, guestClient } = new Client()

const SingleSpaAdditions = () => {
  const includeSingleSpa = !inP3xIframe() && !inIframe()
  if (!includeSingleSpa) return null

  const importMapEnvs = {
    dev: '/js/mfe/dev-importmap.json',
    nonProd: 'https://d2fztdku195ic9.cloudfront.net/nonprod-importmap.json',
    prod: 'https://d14j28xcj7id8.cloudfront.net/prod-importmap.json'
  }

  const prodOrNon =
    process.env.REACT_APP_API_HOST === 'https://tap.joinmosaic.com' ||
    process.env.REACT_APP_API_HOST === 'https://tap.sandbox.joinmosaic.com'
      ? 'prod'
      : 'nonProd'

  const env = process.env.REACT_APP_LOCAL_MFE ? 'dev' : prodOrNon

  return (
    <>
      {/* Add single spa */}
      <ScriptTag src="https://cdn.jsdelivr.net/npm/import-map-overrides@2.2.0/dist/import-map-overrides.js"></ScriptTag>
      <ScriptTag src="https://cdn.jsdelivr.net/npm/systemjs@6.8.3/dist/system.min.js"></ScriptTag>
      <ScriptTag src="https://cdn.jsdelivr.net/npm/systemjs@6.8.3/dist/extras/amd.min.js"></ScriptTag>
      <ScriptTag src="https://cdn.jsdelivr.net/npm/single-spa@5.9.0/lib/system/single-spa.min.js"></ScriptTag>

      <ScriptTag
        type="systemjs-importmap"
        src="https://d14j28xcj7id8.cloudfront.net/base-importmap.json"
      ></ScriptTag>
      <ScriptTag type="systemjs-importmap" src={importMapEnvs[env]}></ScriptTag>
    </>
  )
}

type State = {
  themeOverrideKey: string,
  loading: boolean,
  guestData: {},
  networkUnreachableError?: Error
}

type Props = {}

class WrappedApp extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      themeOverrideKey:
        safeSessionStorage.getItem(sessionStorageKeys.themeOverride) ||
        'default',
      loading: true,
      guestData: {}
    }
  }

  setThemeOverrideKey = themeOverrideKey => {
    safeSessionStorage.setItem(
      sessionStorageKeys.themeOverride,
      themeOverrideKey
    )
    this.setState({
      themeOverrideKey
    })
  }

  getTheme = themeColors => {
    // Theme switching only necessary in development environment
    const { themeOverrideKey } = this.state
    let updatedColors = colors
    if (themeOverrideKey === themeOverrideKeys.default && themeColors) {
      updatedColors = {
        ...colors,
        primary: themeColors.primaryColor,
        secondary: themeColors.secondaryColor
      }
    }
    return createTheme(updatedColors)
  }

  componentDidMount() {
    this.getGuestClient()
    DeviceHelper.loadFingerprintJS()
  }

  getGuestClient = async () => {
    this.setState({ loading: true })
    const channelPartnerId =
      safeSessionStorage.getItem(sessionStorageKeys.channelPartnerId) || ''
    try {
      const myData = await guestClient.query({
        query: GetClientIds,
        variables: { channelPartnerId }
      })

      this.setState({ loading: false, guestData: myData.data })
    } catch (networkUnreachableError) {
      this.setState({ loading: false, networkUnreachableError })
    }
  }

  static renderApp = (channelPartnerTheme: String): Node => (
    <App themeColors={channelPartnerTheme} />
  )

  static renderError = (): Node => (
    <GenericErrorPage
      title={l10n.notFound.networkErrorTitle}
      descriptionOverviewText={l10n.notFound.descriptionNetworkErrorLn1}
      descriptionDetailText={l10n.notFound.descriptionNetworkErrorLn2}
      showErrorCode={false}
      showBackButton={false}
    />
  )

  render() {
    const { loading, guestData, networkUnreachableError } = this.state

    if (loading) return <Loading />

    const segmentClientId = get(guestData, 'clientIds.segment')
    AnalyticsHelper.init()

    const channelPartnerTheme = get(guestData, 'channelPartner.theme', null)
    return (
      <>
        {/* <CssBaseline /> */}
        <LaunchDarklyProvider
          clientId={get(guestData, 'clientIds.launchDarkly')}
        >
          <SessionBlocker>
            <SingleSpaAdditions />
            {networkUnreachableError
              ? WrappedApp.renderError()
              : WrappedApp.renderApp(channelPartnerTheme)}
          </SessionBlocker>
        </LaunchDarklyProvider>
      </>
    )
  }
}

const SessionBlockerComponent = ({ ldFlags, children, location }) => {
  const pathname = get(location, 'pathname')
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    if (isEmpty(ldFlags) || !pathname) return
    if (getUUIDFromPath(pathname) && clearSessionOnAnyRefreshToken(ldFlags)) {
      destroyUserAccessToken()
    }
    setMounted(true)
  }, [ldFlags, pathname])

  return mounted ? children : null
}

const SessionBlocker = flow(
  withRouter,
  withLaunchDarkly
)(SessionBlockerComponent)

ReactDOM.render(
  <ErrorBoundary>
    <HelmetProvider>
      <ScreenTitle />
      <ApolloProvider client={client}>
        <Provider>
          <Router history={history}>
            <ScrollToTopOnPageNav />
            {window.isProduction ? ( // window.isProduction is set in public/js/monitoring.js
              <WebsiteMonitoring>
                <WrappedApp />
              </WebsiteMonitoring>
            ) : (
              <WrappedApp />
            )}
          </Router>
        </Provider>
      </ApolloProvider>
    </HelmetProvider>
  </ErrorBoundary>,
  // $FlowFixMe
  document.getElementById('root')
)

// Add service worker registration here
