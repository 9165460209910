// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import l10n from 'properties/translations'
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core'

import styles from './LandlineConfirmDialog.styles'

type Props = {
  classes: {
    landlineWarning: string
  },
  hideLandlineConfirmModal: () => void,
  allowLandlineNumber: () => void
}
const LandlineConfirmDialog = ({
  classes,
  hideLandlineConfirmModal,
  allowLandlineNumber
}: Props) => {
  const { modalTitle, modalContent, backButton, emailOnlyButton } =
    l10n.apply.mobileVerificationScreen.phoneErrorTypes.landlineWarning

  return (
    <>
      <DialogTitle className={classes.landlineModalTilet}>
        {modalTitle}
      </DialogTitle>
      <DialogContent className={classes.landlineModalContent}>
        <DialogContentText>{modalContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={hideLandlineConfirmModal}
          className={classes.landlineModalActionButtons}
        >
          {backButton}
        </Button>
        <Button
          onClick={allowLandlineNumber}
          className={classes.landlineModalActionButtons}
        >
          {emailOnlyButton}
        </Button>
      </DialogActions>
    </>
  )
}
export default withStyles(styles)(LandlineConfirmDialog)
