// @flow
import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { flow, isEmpty } from 'lodash'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import windowPostMessageTypes from 'constants/enums/windowPostMessageTypes'
import errorTypes from 'constants/enums/errorTypes'
import ErrorToast from 'components/shared/ErrorToast'
import SignContractGuest from 'screens/apply/queries/SignContractGuest.graphql'
import { getErrorType } from 'util/ErrorHelper'
import { getDeviceFingerprint } from 'util/DeviceHelper'
import { inIframe } from 'util/EnvironmentHelper'
import { postMessageToParentWindow } from 'util/IframeHelper'
import SetupStyles from '../Setup.styles'
import signatorRolesEnum from 'constants/enums/signatorRoles'
import SignContractGraphql from 'screens/apply/queries/SignContract.graphql'

const styles = theme => ({
  ...SetupStyles(theme)
})

type Props = {
  localizedTranslations: Object,
  classes: any,
  salesperson: Object,
  guest: boolean,
  client: Object,
  contractSigned: boolean,
  onContractSigned: Function
}

const IntegratorContent = ({
  localizedTranslations: l10n,
  classes,
  salesperson,
  guest,
  client,
  customer,
  contractSigned,
  onContractSigned
}: Props) => {
  const [calledSignContract, setCalledSignContract] = useState(contractSigned)
  const [showSubmitError, setShowSubmitError] = useState(false)

  const postComplete = () => {
    postMessageToParentWindow({ name: windowPostMessageTypes.complete })
  }

  useEffect(() => {
    if (!guest && inIframe()) {
      postComplete()
    }
  }, [guest])

  const handleToastClose = () => {
    setShowSubmitError(false)
  }

  // Returning from an Integrator Docusign:
  useEffect(() => {
    if (guest && !calledSignContract) {
      const signContractGuest = async client => {
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(window.location.search)
        const parsedRefreshToken = searchParams.get('refreshToken')
        const urlParts = url.pathname.split('/')
        const signatorRole = urlParts[3] || signatorRolesEnum.PRIMARY_APPLICANT
        try {
          setCalledSignContract(true)
          const guestResult = await client.mutate({
            mutation: SignContractGuest,
            variables: {
              refreshToken: parsedRefreshToken,
              deviceFingerprint: await getDeviceFingerprint(),
              signatory: signatorRole
            }
          })
          if (get(guestResult, 'data.signContract') === null) {
            // Guest signing failed because we have already used the docusign refresh token
            // [we had to in Complete#retrieveUserAccessTokenWhenCookiesMissing].
            // Try again to signContract as user, which accomplishes the same goal, now that we are logged in.
            // This may occur on Safari under certain settings (AOS-328, https://gist.github.com/iansltx/18caf551baaa60b79206)
            // and any other browser that doesn't keep/set cookies when redirected away from our domain (e.g. to docusign).
            await client.mutate({
              mutation: SignContractGraphql,
              variables: {
                signatorRole,
                id: customer.id
              }
            })
          }
          postComplete()
          onContractSigned()
        } catch (error) {
          const errorType = getErrorType(error)
          if (errorType === errorTypes.contractAlreadySigned) {
            setShowSubmitError(true)
          }
        }
      }
      signContractGuest(client)
    }
  }, [guest, calledSignContract, client, onContractSigned, customer])

  const { firstName, lastName } = salesperson
  if (!salesperson || isEmpty(salesperson)) return null
  return (
    <Grid item xs="8">
      <Typography
        className={classes.integratorCompleteContent}
        variant="subtitle1"
      >
        {l10n.setup.integrator.description(firstName, lastName)}
      </Typography>
      <ErrorToast
        open={showSubmitError}
        handleClose={handleToastClose}
        message={l10n.setup.integrator.contractAlreadySigned}
      />
    </Grid>
  )
}

export default flow(withApolloConsumer, withStyles(styles))(IntegratorContent)
