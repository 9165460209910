import { isEmpty } from 'lodash'
import isWithinInterval from 'date-fns/isWithinInterval'
import { parse } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import l10n from 'properties/translations'
import DateHelper from './DateHelper'
import { localizedTranslations } from 'properties/translations'
import { type CustomerShape } from 'screens/customer/shapes/customer'
import { customerLanguageKey } from 'util/CustomerHelper'
import opportunityStatuses from 'constants/enums/opportunityStatuses'
import creditApplicationDecisionStatus from 'constants/enums/creditApplicationDecisionStatuses'
import contractStatus from 'constants/enums/contractStatuses'
import financingPlatformOptions from 'constants/enums/financingPlatformOptions'

const getStartAndEndTimes = (supportType, timezone) => {
  const {
    startTime: start,
    endTime: end,
    friEndTime: friStart,
    friStartTime: friEnd,
    satStartTime: satStart,
    satEndTime: satEnd,
    sunStartTime: sunStart,
    sunEndTime: sunEnd
  } = supportType

  const targetTimezoneNow = utcToZonedTime(new Date(), timezone)
  const targetTimezoneDate = format(targetTimezoneNow, 'yyyy-MM-dd')

  const convertToTargetTimezone = time => {
    if (time) {
      return parse(
        `${targetTimezoneDate} ${time}`,
        DateHelper.contactCardTimeFormat,
        targetTimezoneNow
      )
    }
    return null
  }

  const startTime = convertToTargetTimezone(start)
  const endTime = convertToTargetTimezone(end)
  const friStartTime = convertToTargetTimezone(friStart)
  const friEndTime = convertToTargetTimezone(friEnd)
  const satStartTime = convertToTargetTimezone(satStart)
  const satEndTime = convertToTargetTimezone(satEnd)
  const sunStartTime = convertToTargetTimezone(sunStart)
  const sunEndTime = convertToTargetTimezone(sunEnd)

  return {
    startTime,
    endTime,
    friStartTime,
    friEndTime,
    satStartTime,
    satEndTime,
    sunStartTime,
    sunEndTime
  }
}

const findAvailability = (
  startTime,
  endTime,
  friStartTime,
  friEndTime,
  satStartTime,
  satEndTime,
  sunStartTime,
  sunEndTime,
  timezone
) => {
  const allowedDateTimes = {
    Monday: { startTime, endTime },
    Tuesday: { startTime, endTime },
    Wednesday: { startTime, endTime },
    Thursday: { startTime, endTime },
    Friday: {
      startTime: friStartTime || startTime,
      endTime: friEndTime || endTime
    },
    Saturday: { startTime: satStartTime, endTime: satEndTime },
    Sunday: { startTime: sunStartTime, endTime: sunEndTime }
  }

  const currentTime = utcToZonedTime(new Date(), timezone) // Current time in target timezone (default: Americas/Los_Angeles)
  const currentDay = format(currentTime, 'EEEE') // Current day name in target timezone

  if (
    allowedDateTimes[currentDay].startTime === null ||
    allowedDateTimes[currentDay].endTime === null
  ) {
    return false
  }

  const available = isWithinInterval(currentTime, {
    start: allowedDateTimes[currentDay].startTime,
    end: allowedDateTimes[currentDay].endTime
  })

  return available && typeof available !== 'undefined'
}

const checkAvailability = (supportType, props, timezone) => {
  const { general, defaultSupport, underwriting, welcome, loanpayment } = props

  /**
   *  General Support
   */

  // Get the local time and day in PT
  // Check the time range of current day in PT and show availability text based on that
  if (supportType === general || supportType === defaultSupport) {
    const {
      startTime,
      endTime,
      friStartTime,
      friEndTime,
      satStartTime,
      satEndTime,
      sunStartTime,
      sunEndTime
    } = getStartAndEndTimes(l10n.contactCard.DefaultSupport, timezone)
    return findAvailability(
      startTime,
      endTime,
      friStartTime,
      friEndTime,
      satStartTime,
      satEndTime,
      sunStartTime,
      sunEndTime,
      timezone
    )
  }
  if (supportType === loanpayment) {
    const {
      startTime,
      endTime,
      friStartTime,
      friEndTime,
      satStartTime,
      satEndTime,
      sunStartTime,
      sunEndTime
    } = getStartAndEndTimes(l10n.contactCard.LoanAndPaymentSupport, timezone)
    return findAvailability(
      startTime,
      endTime,
      friStartTime,
      friEndTime,
      satStartTime,
      satEndTime,
      sunStartTime,
      sunEndTime,
      timezone
    )
  }
  if (supportType === welcome) {
    const {
      startTime,
      endTime,
      friStartTime,
      friEndTime,
      satStartTime,
      satEndTime,
      sunStartTime,
      sunEndTime
    } = getStartAndEndTimes(l10n.contactCard.WelcomeCallSupport, timezone)

    return findAvailability(
      startTime,
      endTime,
      friStartTime,
      friEndTime,
      satStartTime,
      satEndTime,
      sunStartTime,
      sunEndTime,
      timezone
    )
  }
  if (supportType === underwriting) {
    const {
      startTime,
      endTime,
      friStartTime,
      friEndTime,
      satStartTime,
      satEndTime,
      sunStartTime,
      sunEndTime
    } = getStartAndEndTimes(
      l10n.contactCard.UnderwritingSupportContractor,
      timezone
    )

    return findAvailability(
      startTime,
      endTime,
      friStartTime,
      friEndTime,
      satStartTime,
      satEndTime,
      sunStartTime,
      sunEndTime,
      timezone
    )
  }
  return false
}

export const getGeneralSupport = cardData =>
  isEmpty(cardData.GeneralSupportContractor, true)
    ? cardData.GeneralSupportCustomer
    : cardData.GeneralSupportContractor

export const getUnderwritingSupport = cardData =>
  isEmpty(cardData.UnderwritingSupportContractor, true)
    ? cardData.UnderwritingSupportCustomer
    : cardData.UnderwritingSupportContractor

export const getWelcomeSupport = cardData =>
  cardData && cardData.WelcomeCallSupport

export const getLoanPaymentSupport = cardData =>
  cardData && cardData.LoanAndPaymentSupport

const isOpportunityStatusPrequalCompleted = (
  opportunityStatus: string
): boolean => opportunityStatus === opportunityStatuses.prequalCompleted

const creditApplicationNeedsReview = (
  creditApplicationStatus: string
): boolean =>
  creditApplicationStatus === creditApplicationDecisionStatus.needsReview

const contractSignedAndBlockedByManualReview = (
  contractStatusFlag: string,
  blockedByManualReview: boolean
): boolean =>
  contractStatusFlag === contractStatus.signed && blockedByManualReview

export const getContactCardData = (
  customerIsBorrower: boolean,
  isOkayToCancelFlag: boolean,
  blockedByManualReview: boolean,
  contractStatusFlag: string,
  creditApplicationStatus: string,
  opportunityStatus: string,
  customer: CustomerShape
) => {
  const contactCardData = {
    GeneralSupportCustomer: {},
    GeneralSupportContractor: {},
    UnderwritingSupportContractor: {},
    UnderwritingSupportCustomer: {},
    WelcomeCallSupport: {},
    LoanAndPaymentSupport: {}
  }

  const l10n = localizedTranslations(customerLanguageKey(customer))

  const {
    GeneralSupportContractor,
    GeneralSupportCustomer,
    UnderwritingSupportContractor,
    UnderwritingSupportCustomer,
    WelcomeCallSupport,
    LoanAndPaymentSupport
  } = l10n.contactCard

  const opportunityPrequalCompleted = isOpportunityStatusPrequalCompleted(
    opportunityStatus
  )

  const creditApplicationStatusNeedsReview = creditApplicationNeedsReview(
    creditApplicationStatus
  )

  const contractSignedAndBlocked = contractSignedAndBlockedByManualReview(
    contractStatusFlag,
    blockedByManualReview
  )

  if (customerIsBorrower) {
    contactCardData.GeneralSupportCustomer = GeneralSupportCustomer

    if (!isOkayToCancelFlag) {
      contactCardData.LoanAndPaymentSupport = LoanAndPaymentSupport
    } else {
      contactCardData.UnderwritingSupportCustomer = UnderwritingSupportCustomer
    }
  } else if (!customerIsBorrower) {
    contactCardData.GeneralSupportContractor = GeneralSupportContractor

    if (opportunityPrequalCompleted) {
      if (creditApplicationStatusNeedsReview) {
        contactCardData.UnderwritingSupportContractor = UnderwritingSupportContractor
      }
    } else if (contractSignedAndBlocked) {
      contactCardData.WelcomeCallSupport = WelcomeCallSupport
    }
  }

  return contactCardData
}

export const getSupportPhoneNumber = ({
  financingType,
  activeProductValue,
  customer
}) => {
  const l10n = localizedTranslations(customerLanguageKey(customer))
  if (
    financingType === financingPlatformOptions.loan ||
    activeProductValue === financingPlatformOptions.loan
  ) {
    return l10n.contactCardLoan.supportPhoneNumber
  }
  if (activeProductValue === financingPlatformOptions.lease) {
    return l10n.contactCardLease.supportPhoneNumber
  }
}

export const getSupportEmail = ({
  financingType,
  activeProductValue,
  customer
}) => {
  const l10n = localizedTranslations(customerLanguageKey(customer))
  if (
    financingType === financingPlatformOptions.loan ||
    activeProductValue === financingPlatformOptions.loan
  ) {
    return l10n.contactCardLoan.supportEmail
  }
  if (activeProductValue === financingPlatformOptions.lease) {
    return l10n.contactCardLease.supportEmail
  }
}

export default checkAvailability
