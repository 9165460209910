// @flow
import React, { useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import { flow } from 'lodash'
import translations from 'properties/translations'
import BubbleFilledIcon from 'images/BubbleFilledIcon'
import BubbleUnfilledIcon from 'images/BubbledUnfilledIcon'
import LaunchDarklyContext from 'util/LaunchDarklyContext'
import LinkComponent from './LinkComponent'
import {
  completeForStage,
  routeForStage,
  bubbleIsActive,
  renderSmallBubble
} from './utils/bubbleUtils'
import styles from './Bubble.styles'
import l10n from 'properties/translations'

const { pipeline } = translations

type Props = {
  complete: boolean,
  small: boolean,
  getBubbleClassName: () => string,
  disabled: boolean
}

const Bubble = ({ complete, small, disabled, getBubbleClassName }: Props) => (
  <>
    {small || disabled ? (
      <BubbleFilledIcon
        alt={pipeline.disabled}
        title={pipeline.disabled}
        className={getBubbleClassName()}
      />
    ) : (
      <>
        {complete ? (
          <BubbleFilledIcon
            alt={pipeline.complete}
            title={pipeline.complete}
            className={getBubbleClassName()}
          />
        ) : (
          <BubbleUnfilledIcon
            alt={pipeline.incomplete}
            title={pipeline.incomplete}
            className={getBubbleClassName()}
          />
        )}
      </>
    )}
  </>
)

type BubbleForStageProps = {
  stage: string,
  data: Object,
  options: { small: boolean },
  disabled?: boolean,
  ariaLabel: string,
  classes: {
    bubble: string,
    notClickableBubble: string,
    notClickableSmallBubble: string,
    bubbleImgSmall: string,
    nodeContainer: string,
    block: string,
    blockDisabled: string,
    blockSmall: string,
    active: string,
    focus: string,
    smallBubble: string,
    pipelineBubble: string
  },
  location: {
    pathname: String
  },
  pipeline: boolean
}

const BubbleForStage = ({
  stage: navigationStage,
  data,
  options = { small: false },
  disabled = false,
  pipeline = false,
  classes,
  location,
  ariaLabel
}: BubbleForStageProps) => {
  const { ldFlags } = useContext(LaunchDarklyContext)

  if (!data.customer) {
    return null
  }

  const complete = completeForStage(navigationStage, data)
  const route = routeForStage(navigationStage, data, ldFlags)
  const small = renderSmallBubble(navigationStage, data)
  const getBubbleClassName = () => {
    if (small) {
      return cx({
        [classes.smallBubble]: true,
        [classes.notClickableSmallBubble]: disabled
      })
    }

    return cx({
      [classes.bubbleImgSmall]: options.small,
      [classes.bubble]: !options.small,
      [classes.active]: bubbleIsActive(route, location.pathname),
      [classes.notClickableBubble]: !pipeline && disabled
    })
  }

  return (
    <LinkComponent
      data-testid={`bubble-linkComponent-${navigationStage}`}
      disabled={small || disabled || pipeline}
      to={route}
      className={cx({
        [classes.nodeContainer]: !pipeline,
        [classes.pipelineBubble]: pipeline,
        [classes.blockSmall]: !!options.small,
        [classes.blockDisabled]: !options.small && small,
        [classes.block]: !options.small && !small,
        [classes.focus]: true
      })}
      aria-label={`${ariaLabel} ${l10n.pipeline.stage} ${
        complete ? l10n.sharedTexts.complete : l10n.sharedTexts.incomplete
      }`}
      tabIndex={0}
    >
      <Bubble
        disabled={disabled}
        complete={complete}
        small={small}
        getBubbleClassName={getBubbleClassName}
      />
    </LinkComponent>
  )
}

export default flow(withRouter, withStyles(styles))(BubbleForStage)
