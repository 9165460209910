// @flow
import React from 'react'
import { Button, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { merge, cloneDeep, get, lowerFirst } from 'lodash'
import userTypes from 'constants/enums/userTypes'
import NumberFormat from 'react-number-format'
import {
  supportPhone,
  DOLLAR_DECIMAL_SCALE,
  DOLLAR_PREFIX,
  contactCardSupportTypes
} from 'properties/properties'
import projectTypes, {
  projectTypeKeys,
  loanStageKeys
} from 'constants/enums/projectTypes'
import errorKeys from 'constants/enums/errorKeys'
import languageKeys from 'constants/enums/languageKeys'
import DateHelper from 'util/DateHelper'
import sharedText from './translations/sharedText.es'
import styles from 'screens/setup/components/commonStyles'
import SuperScriptAmount from 'components/shared/SuperScriptAmount'
import lenderDialogStyles from 'screens/apply/components/summaryTable/LenderDialog.styles'
import translationsBase from './translations.en'
import lendersEnum from 'constants/enums/lenders'
import { type LenderObjectType } from 'util/LenderHelper'
import loanProductTypeEnum from 'constants/enums/loanProductTypes'
import projectStatuses from 'constants/enums/projectStatuses'
import sharedTexts from './translations/sharedText.es'

const useStyles = makeStyles(styles)
const useLenderDialogStyles = makeStyles(lenderDialogStyles)

const projectStatusesTitles = {
  [projectStatuses.Unspecified]: 'No especificado',
  [projectStatuses.Cancelled]: sharedText.cancelled,
  [projectStatuses.InspectionComplete]: 'Inspección Completa',
  [projectStatuses.EquipmentApproved]: 'Equipo Aprobado',
  [projectStatuses.Approved]: 'Aprobado',
  [projectStatuses.New]: 'Nuevo',
  [projectStatuses.InstallComplete]: 'Instalación Completa',
  [projectStatuses.InstallCompletePending]: 'Instalación Completa Pendiente',
  [projectStatuses.InstallConfirmed]: 'Instalación Confirmada',
  [projectStatuses.FinalConfirmation]: 'Confirmación Final'
}

const projectNameToSpanish = projectName => {
  switch (projectName) {
    case projectTypes[projectTypeKeys.OTHER]:
      return 'Otro'
    case projectTypes[projectTypeKeys.HVAC]:
      return 'HVAC'
    case projectTypes[projectTypeKeys.DOORS]:
      return 'Puertas'
    case projectTypes[projectTypeKeys.WINDOWS]:
      return 'Ventanas'
    case projectTypes[projectTypeKeys.ROOFING]:
      return 'Techado'
    case projectTypes[projectTypeKeys.SOLAR_SYSTEM]:
      return 'Sistema de paneles solares'
    case projectTypes[projectTypeKeys.EXTERIOR_REMODEL]:
      return 'Remodelación de exteriores'
    case projectTypes[projectTypeKeys.INTERIOR_REMODEL]:
      return 'Remodelación de interiores'
    case projectTypes[projectTypeKeys.BATTERY]:
      return 'Batería'
    case projectTypes[projectTypeKeys.CUSTOM]:
    default:
      return 'Personalizado'
  }
}

const projectStatusToSpanish = (projectStatus: string) => {
  switch (projectStatus) {
    case projectStatuses.Unspecified:
      return 'No especificado'
    case projectStatuses.Cancelled:
      return sharedText.cancelled
    case projectStatuses.InspectionComplete:
      return 'Inpección Completa'
    case projectStatuses.EquipmentApproved:
      return 'Equipo Aprobado'
    case projectStatuses.EquipmentSubmitted:
      return 'Equipo Enviado'
    case projectStatuses.Approved:
      return 'Aprobado'
    case projectStatuses.New:
      return 'Nuevo'
    case projectStatuses.InstallComplete:
      return 'Instalación Completa'
    case projectStatuses.InstallCompletePending:
      return 'Instalación Completa Pendiente'
    case projectStatuses.InstallConfirmed:
      return 'Instalación Confirmada'
    case projectStatuses.FinalConfirmation:
      return 'Confirmación Final'
  }
}

const stageNameToSpanish = stageName => {
  switch (stageName) {
    case loanStageKeys.completion:
      return 'terminación'
    case loanStageKeys.countersignature:
      return 'refrendo'
    default:
      return stageName
  }
}

const time = {
  now: 'Ahora',
  justNow: 'Ahora mismo',
  hour: 'hora',
  hours: 'horas',
  day: 'día',
  days: 'días',
  yesterday: 'ayer',
  minute: 'minuto',
  minutes: 'minutos',
  indefiniteArticleRegex: 'una|un',
  aboutRegex: 'alrededor de'
}

const projectStatus = {
  subheading: sharedText.project,
  refreshButton: sharedText.refresh
}

// TODO: Check translations start:
const contactCardEmail = {
  emailText: 'Envíenos un email:',
  supportEmail: 'support@joinmosaic.com',
  responseText: '90% respondió dentro de dos horas hábiles.'
}
const contactCardTemplate = {
  type: 'default',
  titleMessage: 'Llámenos si tiene preguntas inmediatas...',
  supportNumber: '1 (855)-746-5551',
  formattedSupportNumber: '18557465551',
  supportText: 'Soporte general',
  startTime: '07:00 am',
  endTime: '06:00 pm',
  satStartTime: '07:00 am',
  satEndTime: '06:00 pm',
  sunStartTime: null,
  sunEndTime: null,
  contactHours: 'De lun a sáb 7am – 6pm, dom cerrado (PT)',
  ...contactCardEmail
}
const contactCardPrequalTemplate = {
  type: 'underwriting',
  titleMessage: '¿Necesita ayuda con su precalificación condicional?',
  supportNumber: '1 (855) 558-6007',
  formattedSupportNumber: '18555586007',
  supportText: 'Soporte de suscripción',
  startTime: '07:00 am',
  endTime: '06:00 pm',
  satStartTime: '07:00 am',
  satEndTime: '06:00 pm',
  sunStartTime: null,
  sunEndTime: null,
  contactHours: 'De lun a sáb 7am – 6pm, dom cerrado (PT)',
  ...contactCardEmail
}
const contactCard = {
  help: 'Ayuda',
  supportAreas: {
    timezonePT: 'America/Los_Angeles'
  },
  time,
  availabilityText: {
    availableText: 'Disponible ahora',
    notavailableText: 'No disponible ahora'
  },
  supportTypes: contactCardSupportTypes,
  DefaultSupport: { ...contactCardTemplate },
  GeneralSupportContractor: { ...contactCardTemplate, type: 'general' },
  GeneralSupportCustomer: { ...contactCardTemplate, type: 'general' },
  UnderwritingSupportContractor: { ...contactCardPrequalTemplate },
  UnderwritingSupportCustomer: { ...contactCardPrequalTemplate },
  LoanAndPaymentSupport: {
    ...contactCardTemplate,
    type: 'loanpayment',
    supportText: 'Asistencia para préstamos y pagos',
    startTime: '07:00 am',
    endTime: '06:00 pm',
    friStartTime: '07:00 am',
    friEndTime: '06:00 pm',
    satStartTime: '07:00 am',
    satEndTime: '06:00 pm',
    sunStartTime: null,
    sunEndTime: null,
    contactHours: 'De lun a sáb 7am – 6pm, dom cerrado (PT)'
  },
  WelcomeCallSupport: {
    type: 'welcome',
    titleMessage: 'Para iniciar una llamada de bienvenida, llame al...',
    supportNumber: '1 (855)-587-2499',
    formattedSupportNumber: '18555872499',
    supportText: 'Asistencia de llamada de bienvenida',
    startTime: '07:00 am',
    endTime: '06:00 pm',
    satStartTime: '07:00 am',
    satEndTime: '06:00 pm',
    sunStartTime: null,
    sunEndTime: null,
    contactHours: 'De lun a sáb 7am – 6pm, dom cerrado (PT)',
    ...contactCardEmail
  }
}

const confirmComplete = {
  stipulationResolution: {
    borrowerMessage:
      'Usted ha sido condicionalmente pre calificado. Sin embargo, aún necesitamos más información para completar nuestra evaluación. ',
    resolveNow: 'Resolver ahora',
    installerMessage:
      'Este cliente ha sido condicionalmente pre calificado. Sin embargo, aún necesitamos más información de parte del cliente para completar nuestra evaluación.'
  }
}
const screenTitles = {
  start: sharedText.start,
  pipeline: 'Tubería',
  setup: 'Finalizar',
  apply: 'Aplicar',
  estimate: 'Estimar',
  reviewAndAccept: sharedText.reviewAndAccept,
  customerInfo: sharedText.customerInfo,
  summary: sharedText.summary,
  setupAutopay: sharedText.setupAutopay,
  dashboard: 'Panel',
  projectDetail: 'Detalles del proyecto',
  applicationFormStatus: 'Estado de la aplicación',
  prequalificationForm: 'Pre-calificación',
  signed: sharedText.signedTitle,
  contract: 'Contrato',
  reviewAutopay: sharedText.reviewAutopay,
  resources: sharedText.resources,
  welcome: sharedText.welcome,
  adminTeam: sharedText.adminTeam,
  adminTeamAdd: sharedText.adminTeamAdd,
  adminAccount: sharedText.adminAccount,
  adminProducts: sharedText.adminProduct,
  adminProjectTypes: sharedText.projectTypes,
  adminBankAccount: sharedText.adminBankAccount
}

// TODO: Check translations stop

const addressTypes = {
  primary: 'Residencia primaria',
  rental: 'Alquiler',
  secondHome: 'Segunda casa'
}

const dashboard = {
  documentAlert: {
    textByType: {
      notUploaded: {
        text: {
          [userTypes.installer]:
            'Your selected project(s) require a Sales Agreement to be uploaded before proceeding. ',
          [userTypes.borrower]: `Los proyectos solares requieren que el instalador cargue el ${sharedTexts.salesAgreement} antes de que se puedan completar sus proyectos.`
        },
        textButton: 'Upload now?'
      },
      rejected: {
        text: {
          [userTypes.installer]:
            'Your selected project(s) require a Sales Agreement to be uploaded before proceeding. ',
          [userTypes.borrower]:
            'La revisión del Acuerdo de Ventas está en espera. El instalador no puede continuar hasta que se proporcione el documento adecuado.'
        },
        textButton: 'Redo upload?'
      },
      pending: {
        text: {
          [userTypes.installer]:
            'The Sales Agreement is pending review. Once it is approved, you can proceed with the project(s). ',
          [userTypes.borrower]:
            'El Acuerdo de Ventas está pendiente de revisión. Una vez que sea aprobado, su(s) proyecto(s) podrán ser finalizado(s).'
        },
        textButton: 'Redo upload'
      },
      accepted: {
        text: {
          [userTypes.installer]:
            'The Sales Agreement has been accepted. You can now proceed with the project(s). ',
          [userTypes.borrower]:
            'El Acuerdo de Ventas ha sido aceptado. Su(s) proyecto(s) ahora pueden ser finalizados.'
        },
        textButton: 'Redo upload'
      }
    }
  },
  projectDetail: {
    identifier: sharedText.projectId,
    notes: sharedText.projectDescription,
    statusText: (projectStatus: string) =>
      projectStatusToSpanish(projectStatus),
    projectNamePrefix: 'Proyecto:',
    projectApproved: sharedText.projectApproved,
    projectNew: sharedText.projectNew,
    borrowerConfirmation: {
      title: '¿Está seguro?',
      Content: ({
        stageName,
        stageCost,
        partnerName
      }: {
        stageName: string,
        stageCost: Int,
        partnerName: string
      }) => (
        <>
          Confirmar esta etapa {stageName} como completada desembolsará a{' '}
          <NumberFormat
            displayType="text"
            thousandSeparator
            fixedDecimalScale
            decimalScale={DOLLAR_DECIMAL_SCALE}
            prefix={DOLLAR_PREFIX}
            renderText={text => <SuperScriptAmount price={text} />}
            value={stageCost}
          />{' '}
          {partnerName}. ¿Le gustaría continuar?
        </>
      ),
      actions: {
        back: 'Volver',
        proceed: 'Sí, proceder'
      }
    },
    stageSection: {
      document: sharedText.document,
      stage: sharedText.projectStage,
      confirmCompletion: sharedText.confirmCompletion,
      waitingConfirmation: sharedText.waitingForConfirmation,
      confirmed: sharedText.confirmed,
      complete: sharedText.completed,
      incomplete: sharedText.incomplete
    },
    projectConfirmed: 'Proyecto confirmado',
    projectComplete: 'Proyecto completo',
    projectPending: 'Proyecto completo pendiente'
  },
  documentRow: {
    completedDateLabel: sharedText.uploaded,
    requiredForProjectType: (projectTypeLabel: string) =>
      `Requerido para proyectos de tipo ${projectNameToSpanish(
        projectTypeLabel
      )}`,
    name: {
      salesAgreement: 'Acuerdo de Ventas'
    }
  }
}

const translations = {
  languageKey: languageKeys.es,
  sharedTexts: sharedText,
  pipeline: {
    info: 'Info',
    apply: 'Aplicar',
    estimate: 'Estimar',
    setup: 'Finalizar',
    loanCancellationAlert: {
      requestedByInstaller: partnerName =>
        `Cancelación solicitada por ${partnerName}`,
      requestedByBorrower: 'Cancelación solicitada por el cliente',
      denied: 'La solucitud de cancelación fue denegada',
      cancelled: 'La cancelación fue aprobada y está completa'
    },
    loanCancellationAlertModal: {
      header: {
        requested: 'Cancelación solicitada',
        denied: 'Solicitud de cancelación denegada',
        cancelled: 'Cancelado'
      },
      body: {
        requestedByInstaller: partnerName =>
          `La cancelación de este préstamo post-financiado ha sido solicitada por ${partnerName}. Mosaic se contactará con el cliente para confirmar.`,
        requestedByBorrower:
          partnerName => `La cancelación de este préstamo post-financiado ha sido solicitada por el cliente. Se requiere acción por parte de ${partnerName} para confirmarlo. Por favor contáctese con nuestro equipo de soporte al (855) 558-6007, de lunes a viernes, 7am-9pm hora del Pacífico.
        Este préstamo post-financiado será cancelado 21 días después de la solicitud a menos que ${partnerName} se contacte con nosotros.`,
        denied:
          'La solicitud de cancelación de este préstamo post-financiado ha sido denegada. No se requieré ninguna acción adicional.',
        cancelled:
          'La solicitud de cancelación de este préstamo post-financiado ha sido aprobada y está completa.'
      }
    }
  },
  apply: {
    addressVerification: {
      cancelButton: sharedText.back,
      continueButton: sharedText.continue,
      projectAddressTitle: 'Dirección del proyecto',
      primaryAddressTitle: 'Dirección principal',
      mailingAddressTitle: 'Dirección para correspondencia',
      multipleAddressDescription: '¿Cuál dirección le gustaría usar?',
      addressWarning:
        ' Cambiar la dirección del proyecto o la dirección principal requerirá firmar nuevos documentos.',
      personalInformation:
        'Por favor confirme que su información personal esté escrita correctamente y que esta es su dirección de email personal a la que puede acceder.',
      description:
        'La dirección que ingresó puede ser inválida. Por favor confirme la dirección de instalación que le gustaría usar más abajo o presione Cancelar para ingresar una nueva dirección.',
      missingSecondaryNumber:
        'La dirección que ingresó puede requerir información adicional. Por favor confirme la dirección de instalación que le gustaría usar más abajo o presione Cancelar para ingresar una nueva dirección.',
      exactAddressMatch:
        'Por favor confirme que esta es su dirección de instalación o presione Cancelar para ingresar una nueva dirección.',
      myAddressLabel: 'Usar la dirección que ingresé:',
      selectAddressButton: 'Usar la dirección seleccionada',
      proceedAnywayButton: 'Proceder de todos modos',
      confirmAddressButton: 'Confirmar dirección',
      suggestedAddressLabel: 'Usar la dirección sugerida:',
      title: 'Verificación de la dirección de instalación',
      confirmTitle: 'Confirme su información',
      vaildNameText: '¿Su nombre está bien escrito?',
      vaildEmailText: '¿Es esta una dirección de email a la que pueda acceder?',
      vaildPhoneNumberText: '¿Este es su número de móvil válido? ',
      addressInvalid: 'Dirección inválida'
    },
    disclosures: sharedText.disclosures,
    disclosure: (
      containerClass: string,
      textClass: string,
      linkClass: string
    ) => (
      <div className={containerClass}>
        <div className={textClass}>
          <span>
            Al hacer click en &quot;Aceptar &amp; Continuar&quot; debajo, usted
            está dando su consentimiento a las siguientes divulgaciones como se
            establece en este documento. Usted acepta los términos de este
            Consentimiento de Llamadas/Mensajes de Texto y Grabación de
            Llamadas. Usted acepta que Mosaic Financing Partners, sus
            cesionarios, sucesores, agentes, representantes, proveedores,
            administradores (colectivamente, &quot;nosotros o &quot;nos) pueden
            comunicarse con usted al número o a los números que ha proporcionado
            en o con su solicitud de crédito o cualquier otro número que nos
            proporcione, incluso a través de marcación telefónica automática y
            sistemas de mensajería de texto para entregar mensajes o con el uso
            de voz pregrabada y/o artificial. Entre otras razones, es posible
            que nos comuniquemos con usted para comercializar productos o
            servicios, con información o preguntas sobre la relación de su
            cuenta, en relación con su(s) solicitud(es) actual(es), futura(s) o
            pasada(s), pagos y otras comunicaciones importantes relacionadas con
            su(s) cuenta(s). Los mensajes telefónicos pueden ser reproducidos
            por una máquina automáticamente cuando se contesta el teléfono,
            tanto si lo contesta usted como otra persona. Estos mensajes también
            pueden ser grabados por su contestador automático.
          </span>
        </div>
        <div className={textClass}>
          Usted también entiende y acepta que, sujeto a la ley aplicable,
          podemos monitorear y/o grabar cualquiera de sus conversaciones
          telefónicas con cualquiera de nuestros representantes para fines de
          capacitación, control de calidad, evidencia y cualquier otro
          propósito. Sin embargo, no tenemos ninguna obligación de supervisar,
          grabar, retener o reproducir dichas grabaciones, a menos que lo exija
          la legislación aplicable.
        </div>
        <div className={textClass}>
          Usted entiende que, cuando reciba tales llamadas o mensajes de texto,
          podría incurrir en un cargo de la empresa que le proporciona los
          servicios de telecomunicaciones. Usted acepta que nosotros no seremos
          responsables ante usted por ningún cargo, inconveniente, molestia o
          pérdida de privacidad en relación con dichas llamadas o mensajes de
          texto. Usted entiende que cualquier persona con acceso a su teléfono o
          cuenta de correo electrónico puede escuchar o leer los mensajes, a
          pesar de nuestros esfuerzos por comunicarnos solo con usted. Si uno de
          los números de teléfono que nos ha proporcionado cambia, o si usted
          deja de ser el propietario, el abonado o el usuario principal de
          dicho(s) número(s) de teléfono, se compromete a notificarnos
          inmediatamente estos hechos para que podamos actualizar nuestros
          registros. Para solicitar información adicional, envíe un mensaje de
          texto con la palabra &quot;HELP (ayuda) a cualquier mensaje de texto
          que reciba o comuníquese con nosotros por teléfono llamando al (855)
          746-7849. Para dejar de recibir mensajes de texto de nosotros, puede
          responder a cualquier mensaje de texto que le enviemos o enviarnos un
          mensaje de texto al 66742 con solo la palabra &quot;STOP&quot;
          (detener). Procesaremos su solicitud para dejar de recibir mensajes de
          texto en un plazo razonable. Para dejar de recibir nuestras llamadas
          automáticas y/o pregrabadas/artificiales, comuníquese con nosotros al
          (855) 746-7849 o
          <a className={linkClass} href="mailto:support@joinmosaic.com">
            support@joinmosaic.com
          </a>
          .
        </div>
      </div>
    ),
    disclosuresItems: [
      '[SPANISH NEEDED] By clicking "Agree & Continue" below, you are consenting to the following disclosures as stated herein. You agree to the terms of this Calling/Text Messaging and Call Recording Consent. You agree that Solar Mosaic LLC and its assigns, successors, agents, representatives, vendors, and servicers, and its lending partners and their assigns, successors, agents, representatives, vendors, and servicers, (collectively, “we,” or “us”) may contact you at the number(s) you have provided on or with your application for credit or any other number you provide to us, including through automated telephone dialing and text messaging systems, to deliver messages or with the use of prerecorded and/or artificial voice. Among other reasons, we may contact you with information or questions about your account relationship; regarding your application(s) or current, future or past account(s); and regarding payments and other important communications relating to your account(s). Telephone messages may be played by a machine automatically when the telephone is answered, whether answered by you or someone else. These messages may also be recorded by your answering machine.',
      '[SPANISH NEEDED] You also understand and agree that, subject to applicable law, we may monitor and/or record any of your phone conversations with any of our representatives for training, quality control, evidentiary, and any other purposes. However, we are not under any obligation to monitor, record, retain, or reproduce such recordings, unless required by applicable law.',
      '[SPANISH NEEDED] You understand that, when you receive such calls or text messages you may incur a charge from the company that provides you with telecommunications services. You agree that we will not be liable to you for any fees, inconvenience, annoyance or loss of privacy in connection with such calls or text messages. You understand that anyone with access to your telephone or email account may listen to or read the messages, notwithstanding our efforts to communicate only with you. If a telephone number(s) you have provided to us changes, or if you cease to be the owner, subscriber, or primary user of such telephone number(s), you agree to immediately give us notice of such facts so that we may update our records. To request additional information, text HELP to any text message you receive from us or contact us by telephone at (855) 746-7849. To stop receiving text messages from us, you may reply to any text message that we send to you or initiate a text message to us at 66742 with only the word “STOP.” We will process your request to stop receiving text messages within a reasonable time. To stop receiving autodialed and/or prerecorded/artificial voice calls from us, please contact us at (855) 746-7849 or support@joinmosaic.com.'
    ],
    disclosuresIntroduction: `
<div style="border: 1px solid black; padding: 16px; margin-bottom: 24px">
## POR FAVOR LEA ATENTAMENTE ESTOS TÉRMINOS Y CONDICIONES QUE CONTIENEN LOS SIGUIENTES TÉRMINOS IMPORTANTES A CONTINUACIÓN:

- AUTORIZACIÓN PARA OBTENER INFORMES DE CRÉDITO AL CONSUMIDOR Y PARA COMPARTIR INFORMACIÓN
- CONSENTIMIENTO PARA COMUNICACIONES Y DIVULGACIONES ELECTRÓNICAS (“CONSENTIMIENTO DE FIRMA ELECTRÓNICA (ESIGN)”)
- CONSENTIMIENTO PARA LLAMADAS AUTOMATIZADAS/MENSAJES DE TEXTO AUTOMATIZADOS Y GRABACIÓN DE LLAMADAS
- ACUERDO AL ARBITRAJE DE CUALQUIER RECLAMACIÓN CONTRA NOSOTROS
</div>
    `,
    confirmIdentity: 'Antes de continuar...',
    confirmIdentityDescription:
      'Mantener segura su información personal y financiera es importante para nosotros. Por favor, confirme su identidad antes de continuar.',
    confirmIdentityError:
      'La información que ingresó no coincide con nuestros registros, por favor intente nuevamente',
    summary: {
      title: sharedText.summary
    },
    contactRep: {
      description: (channelPartnerName: string) =>
        `Por favor contacte a su representante de ${channelPartnerName} y asegúrese de que tengan el monto del préstamo adecuado y el plan de financiamiento seleccionado para continuar.`
    },
    formStatus: {
      menu: {
        editCustomerInfo: 'Editar información del cliente',
        setupAutopay: sharedText.setupAutopay
      },
      processing: {
        description: 'Esto solo debería tomar unos segundos...',
        timeout:
          'Gracias por su aplicación. La respuesta de su oficina de crédito está tardando más de lo usual. Continuaremos intentando, y lo actualizaremos vía email cuando tengamos lista una decisión para usted. Gracias por su paciencia.',
        refresh: sharedText.refresh,
        title: sharedText.processing
      },
      verifyIdentity: {
        description:
          'Verifique la siguiente información que proporcionó anteriormente en su solicitud de crédito.',
        title: 'Verificar identidad',
        applicantLabel: sharedText.applicant
      },
      informationVerification: {
        description:
          'Verifique la siguiente información de su precalificación.',
        title: 'Verificación de información'
      },
      complete: {
        description:
          'Los resultados de precalificación han sido enviados a la direccion de correo electrónico que proporcionó.',
        description2: 'Por favor revise su bandeja de entrada.',
        title: sharedText.complete
      },
      conditionalApproval: {
        title: 'Calificación condicional'
      },
      conditionalApprovalUpload: {
        subHeader: 'Por favor proporcione documentación adicional',
        descriptionLn1:
          'Su envío se ha retenido para una revisión manual adicional de verificación de identidad, la calificación está condicionada a esa revisión adicional.',
        descriptionLn2:
          'Según los detalles de su envío, también necesitamos recopilar documentación adicional.',
        descriptionLn3: 'Enviar documento ahora',
        descriptionLn4:
          'Para continuar con su solicitud, verifique su correo electrónico en busca de un correo electrónico de Mosaic y siga las instrucciones que se encuentran dentro. ',
        descriptionLn4Action:
          'Si no podemos verificar su identidad, es posible que su precalificación sea denegada.'
      },
      conditionalApprovalPhone: {
        subHeader:
          'Por favor esté disponible para recibir nuestra llamada telefónica',
        descriptionLn1:
          'Su envío se ha retenido para una revisión manual adicional de verificación de identidad, la calificación está condicionada a esa revisión adicional.',
        descriptionLn2:
          'Para que podamos completar nuestra revisión y seguir adelante, debemos contactarlo directamente por teléfono para verificación. Esté disponible para recibir nuestra llamada y esté preparado para responder las preguntas de verificación.',
        descriptionLn3: 'Las llamadas normalmente se hacen ',
        descriptionLn3Action: 'dentro de las 24 horas.',
        descriptionLn4:
          'Para continuar con su solicitud, verifique su correo electrónico en busca de un correo electrónico de Mosaic y siga las instrucciones que se encuentran dentro.'
      },
      stipResolutionConfirmation: {
        title: 'Sus documentos fueron recibidos',
        subheading: 'Documentos bajo revisión',
        description:
          'Gracias por enviar su documentación de respaldo. Le enviaremos un correo electrónico tan pronto como tengamos una revisión completa de su solicitud.'
      },
      approved: {
        title: 'Calificado',
        description: financingLimit =>
          `Felicitaciones, califica para financiamiento de hasta ${financingLimit}`,
        financingText: 'Felicitaciones, califica para financiamiento de hasta ',
        totalAmountToFinanceWebbank: 'Monto total del préstamo:',
        totalAmountToFinance: 'Monto total a financiar:',
        yourFinancePlanWebbank:
          'Plan de financiamiento basado en el monto del préstamo:',
        viewOffer: 'Continuar con los términos del préstamo',
        estPerMonth: 'Est. por mes',
        yearsRate: (
          loanTenorMonths: number = 0,
          promoPeriod: number = 0,
          isPromoPeriod: boolean = false
        ) =>
          `${isPromoPeriod ? '+' : ''}${Math.round(
            (loanTenorMonths - promoPeriod) / 12
          )} años `,
        bottomTextRate: (apr: number) =>
          `a ${apr.toFixed(2)}% APR (Tasa de porcentaje anual)`
      }
    },
    handBackText: (onEditOffer: () => {}, className: string) => (
      <>
        <div>
          {
            'Devuelva el dispositivo a su representante para editar la oferta y continuar.'
          }
        </div>
        <Button onClick={onEditOffer} color="primary" className={className}>
          <span>editar oferta</span>
        </Button>
      </>
    ),
    accept: {
      reviewAndAccept: sharedText.reviewAndAccept,
      pleaseReview: 'Revise y acepte su oferta.',
      amountFinancedDeprecated: 'Monto financiado:',
      amountFinanced: 'Monto financiado',
      amountFinancedSubText:
        'El monto del crédito que se le proporcionó a usted o en su nombre.',
      firstPaymentDue: 'Primer pago pendiente:',
      aprDeprecated: 'Tasa de porcentaje anual (“APR”):',
      apr: 'Tasa de porcentaje anual (“APR”) ',
      aprSubText: 'El costo de su crédito como tasa anual.',
      aprDescription: (aprDiscounted: number) =>
        `Quiere reducir su APR al ${aprDiscounted}% y reducir sus pagos mensuales? ***`,
      editOffer: sharedText.editOffer,
      reviewAndSign: sharedText.continue,
      monthlyPaymentDeprecated: 'Pago mensual:',
      monthlyPayment: 'Pagos mensuales ',
      MonthlyPaymentDescriptionBkp: ({
        numMonthlyPayments,
        monthlyPayment,
        date,
        numFinalPayments,
        actualFinalPayment
      }) => {
        const classesViaHook = useStyles()
        return (
          <>
            <b>
              {numMonthlyPayments} pagos de{' '}
              <span data-testid="monthly-payment-description-monthly-payment">
                {monthlyPayment}
              </span>
            </b>{' '}
            <span className={classesViaHook.wrapWhenNecessaryText}>
              comenzando el {date}
            </span>
            <br />
            <b>
              {numFinalPayments} pago final de{' '}
              <span data-testid="monthly-payment-description-actual-final-payment">
                {actualFinalPayment}
              </span>
            </b>
          </>
        )
      },
      MonthlyPaymentDescription: ({
        loanProductType,
        monthlyPaymentObject: {
          numMonthlyPayments,
          numFinalPayments,
          numPaymentsUntilReamortization,
          estimatedFirstPaymentDate,
          numRegularPaymentsAfterReamortization,
          firstPaymentDateAfterReamortization,
          actualFinalMonthlyPayment,
          actualChoiceMonthlyPayment,
          actualStandardMonthlyPayment
        }
      }) => {
        const classesViaHook = useStyles()
        return loanProductType === loanProductTypeEnum.powerSwitch ? (
          <>
            <Typography variant="body2">
              {' '}
              <b>
                {numPaymentsUntilReamortization} pagos de{' '}
                <span data-testid="monthly-payment-description-monthly-payment">
                  {actualChoiceMonthlyPayment}
                </span>
              </b>{' '}
              <span className={classesViaHook.wrapWhenNecessaryText}>
                comenzando el {estimatedFirstPaymentDate}
              </span>
            </Typography>
            <Typography variant="body2">
              {' '}
              <b>
                {numRegularPaymentsAfterReamortization} pagos de{' '}
                <span data-testid="monthly-payment-description-monthly-payment">
                  {actualStandardMonthlyPayment}
                </span>
              </b>{' '}
              <span>
                comenzando el {firstPaymentDateAfterReamortization}, asumiendo
                que no se han realizado pagos
              </span>
            </Typography>
            <Typography variant="body2">
              <b>
                {numFinalPayments} pago final de{' '}
                <span data-testid="monthly-payment-description-actual-final-payment">
                  {actualFinalMonthlyPayment}
                </span>
              </b>
            </Typography>
          </>
        ) : (
          <>
            <b>
              {numMonthlyPayments} pagos de{' '}
              <span data-testid="monthly-payment-description-monthly-payment">
                {actualStandardMonthlyPayment}
              </span>
            </b>{' '}
            <span className={classesViaHook.wrapWhenNecessaryText}>
              comenzando el {estimatedFirstPaymentDate}
            </span>
            <br />
            <b>
              {numFinalPayments} pago final de{' '}
              <span data-testid="monthly-payment-description-actual-final-payment">
                {actualFinalMonthlyPayment}
              </span>
            </b>
          </>
        )
      },
      numberOfPayments: 'Número de pagos:',
      financeCharges: 'Cargos financieros ',
      financeChargesDeprecated: 'Cargos financieros:',
      financeChargesSubText: 'El monto en dólares que le costará su crédito.',
      totalPaymentsDeprecated: 'Pagos totales:',
      totalPayments: 'Pagos totales ',
      promoPeriod: 'Período de promoción',
      loanTerms: 'Términos del préstamo',
      notAvailable: 'N/C',
      customerInfo: sharedText.customerInfo,
      fullName: 'Nombre completo:',
      mobilePhone: 'Número de celular:',
      emailAddress: 'Email:',
      installationAddress: 'Dirección del proyecto:',
      primaryAddress: 'Dirección principal:',
      estimateFootnote: '* Todos los valores se basan en estimados.',
      achFootNote: {
        note1: '*** Sujeto a términos y condiciones. Referirse a  ',
        note2: ' Términos para pago automático con ACH',
        note3: ' para detalles.'
      },
      aSignedCopyOfTheLoanAgreement:
        'Después de firmar, recibirá una copia firmada del contrato de préstamo por correo electrónico y mensaje de texto. Tendrá tres (3) días hábiles para cancelar.',
      ifYouPayOffThePrincipal:
        'Todos los valores representan el saldo principal pagado por completo durante el periodo de promoción. Aunque no hay pagos obligatorios durante el periodo de promoción, recomendamos pagar su saldo principal por completo durante el periodo de promoción para evitar costos adicionales. Si el saldo principal no se liquida por completo durante el periodo de promoción, se cobrará interés diferido a su cuenta a partir de la fecha de inicio del préstamo a una tasa porcentual anual del ',
      loadingTerms: 'Cargando términos...',
      hardCreditInquiry:
        'Siga con la revisión y firme el contrato de préstamo. Cuando realicemos el primer pago a su contratista, podrá ver la verificación en el informe de su crédito.',
      interestDeferred: 'Interés diferido',
      noMandatoryPayments: 'Sin pagos obligatorios',
      noPaymentsFor: (contract: Object) =>
        `Sin pagos por ${get(contract, 'loanProduct.promoPeriod', 0)} meses`,
      loanAgreementError: `Hay un problema con su contrato de préstamo. Comuníquese con ${supportPhone}.`,
      genericError:
        'Algo salió mal. No se puede proseguir a la firma del contrato en este momento.',
      addOnInfo: 'Plazos del plan de mantenimiento',
      addOnQuanity: 'Cantidad de planes de mantenimiento:',
      addOnRate: 'Tasa por plan de mantenimiento:',
      addOnTotal: 'Costo total mensual:',
      addOnDisclosure: (
        <span>
          {
            'El plan de mantenimiento puede cancelarse en cualquier momento; sin embargo, la cancelación del plan de mantenimiento requiere mantenimiento continuo de las unidades de acuerdo con su garantía extendida. Consulte los términos de su garantía antes de la cancelación.'
          }
          <br />
          <br />
          {
            'Los planes de mantenimiento deben estar al corriente antes de las visitas del plan de mantenimiento.'
          }
          <br />
          <br />
          {
            'El plan de mantenimiento mensual se ejecutará durante el plazo completo del préstamo, incluso después de que el préstamo se haya pagado por completo, a menos que decida cancelarlo antes.'
          }
        </span>
      ),
      project: sharedText.projects,
      stage: 'Etapa de proyecto',
      stageName: 'Etapa',
      projectStageDescription: (projectName, stageName, amount) =>
        `${projectNameToSpanish(projectName)}, ${
          sharedText.stage
        } de ${stageNameToSpanish(stageName)} ${amount}`,
      optOutAddOnDisclosure:
        'Su unidad podría requerir mantenimiento continuo de acuerdo con las garantías asociadas con su HVAC. Consulte su garantía para conocer más detalles.',
      stagedFunding: {
        disbursements:
          'Se realizarán desembolsos a medida que se complete cada etapa. Los intereses comenzarán a acumularse en la fecha de cada desembolso.',
        personalInfo: 'Revise su información personal.'
      },
      lenderSection: {
        lenderLabel: 'Prestador',
        FinancingOfferedBy: ({
          name,
          className,
          isConnexusLender = false
        }: {
          name: string,
          className: string,
          isConnexusLender: string
        }) => (
          <>
            Su financiamiento es ofrecido por{' '}
            <span className={className}>{name}</span>.{' '}
            {isConnexusLender &&
              'Crearemos una membresía para usted si aún no tiene una.'}
          </>
        ),
        label: 'Prestador',
        Proceed: ({ name }: { name: string }) => (
          <>
            Para proceder con el préstamo, necesitará volverse miembro de {name}
            . No hay costo para usted por unirse. Mosaic financiará los $10
            necesarios para su membresía.{' '}
          </>
        ),
        learnMore: 'Aprender más'
      }
    },
    lender: {
      [lendersEnum.CONNEXUS]: {
        name: 'Connexus Credit Union',
        shortName: 'Connexus',
        association: 'Asociación Connexus'
      },
      [lendersEnum.MOSAIC]: {
        name: 'Solar Mosaic LLC',
        shortName: 'Mosaic'
      }
    },
    lenderDialog: {
      title: 'Prestador',
      close: sharedText.close,
      switchLender: 'cambiarse a otro prestador',
      switchingLender: 'Cambiando prestador...',
      Text: ({
        lender,
        linkComponent,
        switchLenderLinkComponent,
        lenderAssociationComponent,
        showSwitchLenderLink,
        customer,
        refetch,
        closeModal,
        setLoading
      }: {
        lender: LenderObjectType,
        linkComponent: React$PureComponent,
        switchLenderLinkComponent: React$PureComponent,
        lenderAssociationComponent: React$PureComponent,
        showSwitchLenderLink: boolean,
        customer: CustomerShape,
        refetch: Function,
        closeModal: Function,
        setLoading: boolean
      }) => {
        const classes = useLenderDialogStyles()
        const LinkComponent = linkComponent
        const SwitchLenderLinkComponent = switchLenderLinkComponent
        const LenderAssociationComponent = lenderAssociationComponent
        return (
          <Typography
            variant="body2"
            component="p"
            className={classes.proceedText}
            data-testid="proceed-text"
          >
            Usted deberá convertirse en miembro de{' '}
            <LinkComponent lender={lender} /> para continuar con este préstamo.
            La membresía requiere la apertura de una cuenta de ahorros y una
            donación a la <LenderAssociationComponent lender={lender} />. Mosaic
            pagará la tarifa requerida para hacerlo elegible para la membresía
            desde el inicio de su cuenta con {lender.shortName}.
            Específicamente, Mosaic financiará el depósito inicial de $5 para
            abrir una cuenta de ahorros y donará $5, en su nombre, a la{' '}
            {lender.association}. Usted será responsable de mantener su
            membresía. Si ya es miembro de {lender.name}, esto no creará una
            nueva membresía para usted.{' '}
            {showSwitchLenderLink ? (
              <>
                Si no desea afiliarse a esta cooperativa de crédito, es posible
                que pueda{' '}
                <SwitchLenderLinkComponent
                  lender={lender}
                  customer={customer}
                  refetch={refetch}
                  closeModal={closeModal}
                  setLoading={setLoading}
                />
                .
              </>
            ) : (
              <>
                Si no desea afiliarse a esta cooperativa de crédito, por favor
                contacte a su representante para discutir sus opciones de pago.
              </>
            )}
          </Typography>
        )
      }
    },
    emailVerification: {
      title: 'Acción requerida',
      subTitle: 'Consentimiento para recibir comunicaciones electrónicas',
      emailSign: 'Confirme su dirección de correo electrónico',
      waiting: 'Esperando un formulario de respuesta',

      description: (email: string) => (
        <>
          {
            'Para poder continuar con su préstamo, debemos verificar que la dirección de correo electrónico '
          }
          <b>{email ? `${email} ` : 'arriba'}</b>
          {` le pertenece. Haga clic en el botón de abajo para enviarse un correo electrónico con `}
          <b>
            {`el asunto: "Acción requerida: Consentimiento para recibir comunicaciones electrónicas"`}
          </b>
          . Haga clic en el vínculo para completar la verificación.
        </>
      ),
      sendEmail: 'Enviar email ahora',
      sent: date => `Enviado el ${date}`,
      resendEmail: 'Reenviar email',
      never: 'No enviado aún',
      userLinkEmailVerification: {
        needToVerify:
          'Para continuar con su préstamo,necesitamos verificar que la dirección de correo electrónico arriba le pertenece.',
        pleaseOpen:
          'Abra el correo con el asunto "Acción requerida: Verifique su dirección de correo electrónico" y siga el enlace para confirmar que tiene acceso a esta cuenta de correo electrónico.'
      }
    },
    signed: {
      continue: sharedText.continue,
      description:
        'Consulte su email o SMS para obtener una copia firmada de su contrato.',
      title: sharedText.signedTitle,
      viewAgreement: sharedText.viewAgreement
    },
    agreeAndContinue: 'Aceptar y continuar',
    agreeToAllDisclosures:
      'Estoy de acuerdo con los Términos y Condiciones listados arriba.',
    termsAndConditionsNotAccepted:
      'Para precalificar para crédito, por favor acepte estos términos y condiciones.',
    titleAttestation:
      'Declaro que soy el propietario de la dirección del proyecto a mi nombre o directamente a través de un fideicomiso del que soy fiduciario.',
    titleAttestationNotAccepted:
      'Para precalificar para el crédito, la declaración anterior debe aplicarse a usted y debe marcar la casilla.',
    verifyIdentityDescription:
      'Verifique la siguiente información que proporcionó anteriormente en su solicitud de crédito.'
  },
  setup: {
    confirmation: {
      bankFields: {
        accountHolderFullName: 'Nombre del titular de la cuenta:',
        accountNumber: `${sharedText.accountNumber}:`,
        accountType: 'Tipo de cuenta:',
        accountTypeLabel: 'Tipo de cuenta',
        bankName: 'Nombre del banco:',
        routingNumber: 'Número de redireccionamiento:'
      },
      buttons: {
        cancel: sharedText.goBack,
        disclosureText1: 'Estoy de acuerdo con los ',
        disclosureText2: 'términos para pagar con ACH.',
        submit: sharedText.continue,
        disclosureError:
          'Para configurar el pago automático, acepte estos términos.'
      },
      descriptionPart1:
        'El monto del pago debitado mensualmente en o después de las fechas establecidas en mi Contrato de Préstamo será ',
      descriptionPart2:
        '. Si el monto adeudado varía de este monto, Mosaic notificará al menos diez (10) días antes de la fecha programada del débito.',
      descriptionPart3: 'Disfrutará de ',
      descriptionPart4:
        ' Sin Pagos + Intereses Diferidos. Si el saldo no se paga en su totalidad en ',
      descriptionPart5:
        ', el interés diferido se cargará a su cuenta a partir de la fecha del desembolso de fondos a una tasa de porcentaje anual (APR) de ',
      descriptionPart6:
        'Para evitar cargos por intereses, debe pagar la cantidad financiada en su totalidad antes de la fecha de vencimiento de la promoción que figura en sus estados de cuenta. Puede realizar los pagos de su préstamo en línea, con cheque o por teléfono. Revise sus declaraciones para obtener más detalles.',
      descriptionWithoutAddOn1:
        'El (los) monto(s) de pago debitado(s) mensualmente será(n) ',
      descriptionWithoutAddOn2:
        ' para el pago mensual del préstamo. Si los montos debitados varían, Mosaic proporcionará un aviso al menos diez (10) días antes de la fecha programada del débito.',
      descriptionWithAddOn1:
        'El (los) monto(s) de pago debitado(s) mensualmente será(n) ',
      descriptionWithAddOn2: ' para el pago mensual del préstamo y ',
      descriptionWithAddOn3:
        ' para el (los) plan(es) de mantenimiento mensual. Si los montos debitados varían, Mosaic proporcionará un aviso al menos diez (10) días antes de la fecha programada del débito.',
      errors: {
        [errorKeys.bankAccountInvalidAccountInformation]:
          'La verificación de la cuenta falló para la cuenta ingresada. Por favor verifique que la información sea correcta tal como la ingresó, y que la cuenta tenga suficiente saldo disponible.'
      }
    },
    form: {
      accountHolderFullName: {
        label: 'Nombre del titular de la cuenta'
      },
      accountNumber: {
        error: 'Número de cuenta inválido',
        label: sharedText.accountNumber
      },
      paymentTitle: 'PAGO',
      paymentTitle1: 'PAGO 1',
      paymentTitle2: 'PAGO 2',
      payment1Disclosure:
        'Si configura el pago automático (ACH), su APR se reduciría a ',
      payment2Disclosure:
        'Configure el pago automático ahora y no tendrá que preocuparse de realizar ambos pagos con cheque cada mes.',
      permonthCost1: ' por mes por su préstamo',
      permonthCost2: ' por mes por su plan de mantenimiento',
      accountTypes: {
        checking: {
          label: sharedText.checking
        },
        savings: {
          label: sharedText.savings
        }
      },
      bankName: {
        label: 'Nombre del banco'
      },
      buttons: {
        done: sharedText.done,
        continue: sharedText.continue,
        doItLater: 'Omitir'
      },
      descriptionPart1: 'Si realiza pagos por ACH, su APR se reduciría a ',
      descriptionPart2: ' y sus pagos mensuales se reducirían en un ',
      descriptionPart3: ', a ',
      descriptionPart4: '.',
      routingNumber: {
        label: sharedText.routingNumber,
        minDigitsError: 'El número de redireccionamiento debe tener 9 dígitos',
        numberError: 'Número de redireccionamiento inválido'
      },
      title: sharedText.setupAutopay,
      reviewAutopayTitle: sharedText.reviewAutopay,
      titleNoContract: 'Sin contrato aún',
      textNoContract:
        'Puede configurar el pago automático después de completar su solicitud.'
    },
    thankYou: {
      title: 'Gracias',
      description: 'Ha cambiado con éxito a pago por ACH.'
    },
    setupSkipped: {
      title: 'OK, gracias',
      description:
        'Le enviaremos un recordatorio sobre la opción de registrarse para el pago automático más tarde. '
    },
    integrator: {
      title: '¡Gracias!',
      description: (firstName: string, lastName: string) =>
        `¡Listo! Le notificaremos a su representante ${firstName} ${lastName} que ha completado el financiamiento y ellos le informarán sobre los próximos pasos.` // Needs better translation
    }
  },
  shared: {
    loading: 'Cargando...'
  },
  stateMenu: {
    state: 'Estado'
  },
  customer: {
    streetErrorMessage: [
      'La dirección no puede contener sólo números.',
      'La dirección debe tener menos de 50 caracteres.'
    ],
    zipErrorMessage: 'El código postal debe contener 5 dígitos.',
    phoneNumberHelpText: {
      prependingText: 'Agregue un ',
      buttonText: 'número de móvil alternativo',
      appendingText: ', si tiene.',
      completeText: 'Agregue un número de móvil alternativo, si tiene.'
    },
    disclosures: {
      authorization:
        'Autorización para obtener sus Reportes de Consumidor y Compartir Información',
      electronic: 'Consentimiento de Comunicaciones Electrónicas',
      textingCalling:
        'Consentimiento de grabaciones de llamadas y mensajes de texto',
      arbitration: 'Disposición sobre arbitraje',
      webbankLoan:
        'Todos los Préstamos de mejora del hogar hechos por Webbank, miembro de FDIC',
      patriotContent:
        'DIVULGACIÓN DE LA LEY PATRIÓTICA: Para ayudar al gobierno a combatir la financiación de actividades terroristas y relacionadas al lavado de dinero, la ley federal requiere que las instituciones financieras obtengan, verifiquen y graben información que identidica a cada persona que abre una cuenta. Lo que esto significa para usted: Cuando abre una cuenta, le preguntaremos su nombre, dirección, fecha de nacimiento, y otra información que nos permitirá identificarlo. Quizá también le pidamos ver su licencia de conducir u otros documentos que lo identifiquen.'
    },
    loanId: (loanId: number) => `ID del préstamo: ${loanId || ''}`,
    projectAddress: sharedText.projectAddress,
    ssn: 'Número de Seguro Social (SSN)',
    ssnErrorMessage:
      'Por favor ingrese un Número de Seguro Social (SSN) válido',
    ssnHelpText: 'Sólo números, sin guiones.',
    email: sharedText.emailAddress,
    emailOptionalEmphasis:
      '(Opcional. El cliente recibirá un enlace a la aplicación via email)',
    phoneNumber: sharedText.phoneNumber,
    phoneNumberErrorMessage: 'Debe ser un número de móvil válido',
    phoneNumberMinLength: 'Debe ser un número de móvil válido',
    phoneNumberAlternate: 'Número de móvil alternativo',
    emailErrorMessage: sharedText.emailMustBeValid,
    borrowerFirstTime: {
      title: '¡Le damos la bienvenida a su aplicación de crédito!',
      subTitle:
        'Desde ahora hasta que su proyecto esté completo, puede usar esta aplicación para:',
      capabilities: [
        'Completar su precalificación',
        'Firmar y acceder a documentos del préstamo',
        'Inscribirse al pago automático'
      ],
      continue: sharedText.continue
    },
    editDisclaimer: (phone: string, supportMail: string) => (
      <>
        {`Si necesita hacer modificaciones adicionales, por favor contacte a atención al cliente al +${phone} o `}
        <Link href={`mailto:${supportMail}`}>{supportMail}</Link>
      </>
    ),
    lastModifiedBy: (when: String) =>
      `Modificado hace ${lowerFirst(
        DateHelper.distanceOfTimeInWords(languageKeys.es, when)
      )}`,
    dob: 'Fecha de nacimiento',
    dobDayErrorMessage: 'La fecha debe ser válida.',
    dobYearErrorMessage: 'El año debe ser válido.',
    dobYearRangeAlert:
      'Confirme que la fecha de nacimiento que ingresó sea correcta.',
    dobDay: 'Día',
    dobYear: 'Año',
    dobMonth: 'Mes',
    verifySsn: 'Número de Seguro Social (SSN)',
    verifySsnLast4: 'Últimos 4 dígitos de su Número de Seguro Social (SSN)',
    lastFourOfSsn: 'Últimos 4 dígitos de su Número de Seguro Social (SSN)',
    verifySsnErrorMessage: 'Número de Seguro Social (SSN) inválido',
    verifySsnHelpText: 'Sólo números, sin guiones.',
    verifySsnLast4HelpText:
      'Últimos 4 dígitos de su Número de Seguro Social (SSN)',
    continue: sharedText.continue,
    streetShort: sharedText.street,
    city: 'Ciudad',
    state: 'Estado',
    zip: 'Código postal',
    firstName: sharedText.firstName,
    lastName: sharedText.lastName,
    personalIncome: 'Ingresos personales (anuales)',
    personalIncomeHelpText:
      'Sus ingresos brutos antes de impuestos. Pensión, manutención infantil, y manutención por separación son opcionales. ',
    readMore: 'Leer más',
    personalIncomeReadMoreText:
      ' Puede incluir cualquier fuente de ingreso que desee para ser considerada como base para reembolsar el préstamo, por ejemplo, salario, sueldo, intereses, dividendos, ingresos de renta, beneficios de jubilación. No es necesario incluir los ingresos de pensión, manutención infantil, y manutención por separación si no desea incluirlos como base para reembolsar el préstamo.',
    highPersonalIncomeAlert:
      'Por favor verifique que sus ingresos personales sean correctos. De ser así, ignore este mensaje.',
    lowPersonalIncomeAlert:
      'Por favor verifique que ingresó sus ingresos personales anuales totales. ',
    fullName: 'Nombre completo',
    projectAddressTypeLabel: 'Uso de la dirección del proyecto',
    showMailingAddress: 'Prefiero recibir correo en una dirección diferente',
    mailingAddress: 'Dirección para correspondencia',
    primaryAddress: 'Dirección principal',
    errorMessages: {
      noSpecialCharacters: 'Solo se permiten letras, apóstofres y guiones',
      noCompoundNames: 'No se permiten nombres compuestos',
      nameMinLength: 'Debe tener por lo menos dos letras',
      onlyLetters: 'Solo letras',
      lastNameErrorMessage:
        'El apellido no puede contener palabras de una sola letra'
    },
    assignedToLabel: 'Referido por (Opcional)',
    confirm: sharedText.confirm
  },
  formFields: {
    required: 'Este campo es requerido'
  },
  monthNames: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  prequalificationForm: {
    captchaError:
      'El captcha enviado no es válido. Por favor actualice la página y vuelva a intentar.',
    thanksContinueBelow:
      '¡Gracias! Por favor continúe más abajo. También le hemos enviado un enlace en el caso de que deba terminar de completarlo más tarde.',
    prequalifyExplanation: (renovatorName: string) =>
      `Verifique si califica para financiamiento a través de Mosaic para el proyecto de mejora de su hogar. Luego, uno de nuestros representantes de ${renovatorName} se contactará para debatir el proyecto con usted.`,
    welcome: (renovatorName: string) =>
      `Le damos la bienvenida a ${renovatorName}`,
    assignToHelpText:
      'El email del representante de ventas que lo refirió a Mosaic. Si nadie lo refirió, deje este campo en blanco.',
    addressBody1:
      'Por favor díganos cuál será la localización del proyecto. También puede proveer una dirección principal y una dirección para recibir correo si su proyecto no está ubicado en su dirección principal.',
    firstNameHelperText: 'Su nombre legal, no un apodo.',
    creditScoreNotice:
      'Enviar esta precalificación no afectará su calificación crediticia',
    informationIsSecured: 'Su información está segura',
    SSL: 'SSL',
    bitEncrypted: 'Cifrado de 128-bit',
    agreeAndContinue: 'Aceptar y continuar',
    pleaseTellUsAboutYourself:
      'Cuéntenos más sobre usted. No se preocupe, esta precalificación no hará una investigación permanente en su crédito.',
    checkAndTryAgain:
      'Por favor verifique los datos ingresados y vuelva a intentarlo.',
    submit: sharedText.submit,
    error:
      'Hubo un error en el servidor. Por favor vuelva a intentalo nuevamente más tarde.',
    title: 'Precalificación',
    initialHelpText:
      'Por favor ingrese una dirección de email válida a la que pueda acceder y un número de teléfono móvil para recibir notificaciones instantáneas acerca de su proyecto.',
    notLiveYetDialog: {
      title: 'Oops, esto no está activo todavía.',
      body: (partnerName: string) =>
        `Una vez que ${partnerName} esté en funcionamiento usted podrá enviar su aplicación aquí y precalificar para financiamiento a través de Mosaic.`
    },
    jumpToErrors: 'Ir a los errores',
    FormErrorsText: ({ classes, children }) => (
      <Typography
        variant="body2"
        component="span"
        className={classes.errorText}
      >
        Hay algunos errores en el formulario de arriba. {children}
      </Typography>
    )
  },
  foundation: {
    applicationContent: {
      errorMessages: {
        textValidatorEmailCheck: 'isEmail'
      }
    }
  },
  installComplete: {
    modal: {
      title: 'Request confirmation',
      header: {
        OnlyEmail: (email: string) => {
          const modalStyles = useStyles()
          return (
            <>
              Le enviaremos por email (
              <span className={modalStyles.itemBoldWordBreak}>{email} </span>)
              al cliente una solicitud para confirmar que esta etapa de este
              proyecto está completa.
            </>
          )
        },
        EmailAndPhone: (email: string, phone: string) => {
          const modalStyles = useStyles()
          return (
            <>
              Le enviaremos por email (
              <span className={modalStyles.itemBoldWordBreak}>{email} </span>) y
              por mensaje de texto (
              <span className={modalStyles.itemBold}>{phone} </span>) al cliente
              una solicitud para confirmar que esta etapa de este proyecto está
              completa.
            </>
          )
        }
      },
      projectType: sharedText.projectType,
      projectCost: sharedText.projectCost,
      projectName: sharedText.projectName,
      stageName: sharedText.stageName,
      stageCost: sharedText.stageCost,
      customer: sharedText.customer,
      projectAddress: sharedText.projectAddress
    }
  },
  contactCard,
  addressTypes,
  dashboard,
  time,
  projectStatus,
  screenTitles,
  projectStatusesTitles,
  confirmComplete
}

export default (_ignoredSharedText: Object) =>
  merge(cloneDeep(translationsBase(sharedText)), translations)
