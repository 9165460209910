// @flow
import React from 'react'
import type { Node } from 'react'
import { Subscribe } from 'unstated'
import BannerContainer from 'store/BannerContainer'
import AccessModeContainer from 'store/AccessModeContainer'
import l10n from 'properties/translations'

type Props = { handleClick: () => {}, children: () => Node }

const DemoCustomerEdit = ({ handleClick, children }: Props) => (
  <Subscribe to={[BannerContainer, AccessModeContainer]}>
    {(banner, { demoMode }) => {
      const newHandleClick = demoMode
        ? () => {
            banner.open({
              bodyText: l10n.apply.formStatus.demoEdit
            })
          }
        : handleClick
      return children(newHandleClick)
    }}
  </Subscribe>
)

export default DemoCustomerEdit
