// @flow
import loadable from '@loadable/component'
import basePaths from 'constants/enums/basePaths'
import featureFlags from 'constants/enums/featureFlags'
import userRoles from 'constants/enums/userRoles'
import userTypes from 'constants/enums/userTypes'
import Loadable from 'util/Loadable'
import AdminProducts from 'screens/admin/products/AdminProducts'
import TeamMembersForm from 'screens/admin/team/TeamMembersForm'
import TokenExpired from 'components/shared/TokenExpired'
import ForgotPassword from 'components/shared/ForgotPassword'
import ForgotPasswordEmail from 'components/shared/ForgotPasswordEmail'
import BankAccountForm from 'screens/admin/bankAccount/BankAccountForm'
import Onboarding from 'screens/onboarding/Onboarding'
import { DashboardGuard, SetupGuard } from 'appConfig'
import Resources from 'screens/resources/Resources'
import VerifyIdentity from 'screens/apply/ApplicationFormStatus/VerifyIdentity'
import permissionsEnum from 'constants/enums/permissions'
import { UploadDocumentsScreen } from 'screens/apply/UploadDocuments'
import { ApplicationToCustomerScreen } from 'screens/apply/ApplicationToCustomer'
import { NewExperienceScreen } from 'screens/login/NewExperience'

const ApplyWrapper = Loadable(import('screens/apply/ApplyWrapper'))
const Admin = Loadable(import('screens/admin/Admin'), null)
const AdminTeam = Loadable(import('screens/admin/team/AdminTeam'))
const AdminProjectTypes = Loadable(
  import('screens/admin/projectCustomization/AdminProjectTypes')
)
const AdminAccount = Loadable(import('screens/admin/account/AdminAccount'))
const Customer = Loadable(import('screens/customer/Customer'))
const Logout = Loadable(import('screens/login/Logout'))
const Pipeline = Loadable(import('screens/pipeline/Pipeline'))
const Quote = Loadable(import('screens/quote/Quote'))
const StartWrapper = Loadable(import('screens/start/StartWrapper'))
const Settings = Loadable(import('screens/settings/Settings'))
const ContractFrame = Loadable(import('screens/contract/ContractFrame'))
const ReturnFromContractFrame = Loadable(
  import('screens/contract/ReturnFromContractFrame')
)
const ProjectDetail = Loadable(
  import('screens/dashboard/projectDetail/ProjectDetail')
)
const SwiftLink = Loadable(import('screens/admin/swiftlink/SwiftLink'))
const OpportunityByLegacyIdRoute = Loadable(
  import('screens/compatibility/OpportunityByLegacyIdRoute')
)
const LeaseEstimateForm = loadable(() =>
  import('components/lease/LeaseEstimateForm')
)
const LeaseStateSelection = loadable(() =>
  import('components/lease/LeaseStateSelection')
)
const LeaseCustomerInfo = loadable(() =>
  import('components/lease/LeaseCustomerInfo')
)
const LeaseEstimatePreview = loadable(() =>
  import('components/lease/LeaseEstimatePreview')
)
const LeaseEstimateHandoff = loadable(() =>
  import('components/lease/LeaseEstimateHandoff')
)
const LeaseApplicationHandoff = loadable(() =>
  import('components/lease/LeaseApplicationHandoff')
)
const LeaseEstimateReview = loadable(() =>
  import('components/lease/LeaseEstimateReview')
)
const LeaseSendEngagement = loadable(() =>
  import('components/lease/LeaseSendEngagement')
)
const LeaseEstimateSent = loadable(() =>
  import('components/lease/LeaseEstimateSent')
)
const LeaseApplicationApproved = loadable(() =>
  import('components/lease/LeaseApplicationApproved')
)
const LeasePipeline = loadable(() => import('components/lease/LeasePipeline'))
const LeaseCreditApplication = loadable(() =>
  import('components/lease/LeaseCreditApplication')
)
const LeaseCreditDecision = loadable(() =>
  import('components/lease/LeaseCreditDecision')
)
const LeaseCreditDecisionDeclinedOrBlocked = loadable(() =>
  import('components/lease/LeaseCreditDecisionDeclinedOrBlocked')
)
const LeaseSelectEstimate = loadable(() =>
  import('components/lease/LeaseSelectEstimate')
)
const LeaseOverviewOfTerms = loadable(() =>
  import('components/lease/LeaseOverviewOfTerms')
)
const LeaseDashboard = loadable(() => import('components/lease/LeaseDashboard'))
const LeaseAddInvoice = loadable(() =>
  import('components/lease/LeaseAddInvoice')
)
const LeaseConfirmSystemInfo = loadable(() =>
  import('components/lease/LeaseConfirmSystemInfo')
)
const LeaseCertifyCompletion = loadable(() =>
  import('components/lease/LeaseCertifyProjectCompletion')
)
const LeaseFinancingComplete = loadable(() =>
  import('components/lease/LeaseFinancingComplete')
)
const LeaseConfirmMessageReceipt = loadable(() =>
  import('components/lease/LeaseConfirmMessageReceipt')
)

const LeaseEstimateSaved = loadable(() =>
  import('components/lease/LeaseEstimateSaved')
)

const LoanIdentityVerification = loadable(() =>
  import('components/loan/LoanIdentityVerification')
)

const ResubmitSsn = loadable(() => import('components/loan/ResubmitSsn'))
const SharePendingState = Loadable(
  import('screens/sharePendingState/SharePendingState')
)

const ALL_USER_TYPES_PLUS_LEASE_CUSTOMER: string[] = Object.values(userTypes)

const ALL_USER_TYPES: string[] = ALL_USER_TYPES_PLUS_LEASE_CUSTOMER.filter(
  type => type !== userTypes.customer
)

const {
  admin,
  start,
  settings,
  apply,
  customer,
  contract,
  logout,
  pipeline,
  quote,
  estimate,
  expired,
  passwordForgotten,
  passwordForgottenEmail,
  onboarding,
  dashboard,
  returnFromContract,
  setup,
  sharePendingState,
  swiftlink,
  resources,
  verify,
  verifyCoApp,
  verifyIdentity,
  documentResolution,
  lease,
  offer,
  application,
  newExperience
} = basePaths

export type RouteConfig = {
  path: string,
  showLogo?: boolean,
  component: React$Node,
  allowedUserTypes?: string[],
  allowedUserRoles?: string[],
  featureFlag?: string,
  allowedPermissions?: string[]
}

const privateRoutesConfig: RouteConfig[] = [
  {
    path: '/opportunity/:opportunityId',
    component: OpportunityByLegacyIdRoute,
    allowedUserTypes: [userTypes.installer],
    exact: true
  },
  {
    path: admin,
    showLogo: true,
    component: Admin,
    allowedUserTypes: [userTypes.installer],
    allowedUserRoles: [userRoles.installer.admin, userRoles.installer.owner]
  },
  {
    path: `${admin}/project-types`,
    showLogo: true,
    component: AdminProjectTypes,
    allowedUserTypes: [userTypes.installer],
    allowedUserRoles: [
      userRoles.installer.admin,
      userRoles.installer.owner,
      userRoles.installer.mosaicSupport
    ]
  },
  {
    path: `${admin}/account`,
    showLogo: false,
    component: AdminAccount,
    allowedUserTypes: [userTypes.installer],
    allowedUserRoles: [
      userRoles.installer.admin,
      userRoles.installer.owner,
      userRoles.installer.operations
    ]
  },
  {
    path: `${admin}/team`,
    showLogo: true,
    component: AdminTeam,
    allowedUserTypes: [userTypes.installer],
    allowedUserRoles: [userRoles.installer.admin, userRoles.installer.owner]
  },
  {
    path: `${admin}/bank-account`,
    showLogo: true,
    component: BankAccountForm,
    allowedUserTypes: [userTypes.installer],
    allowedUserRoles: [userRoles.installer.owner]
  },
  {
    path: `${admin}/products`,
    showLogo: true,
    component: AdminProducts,
    allowedUserTypes: [userTypes.installer],
    allowedUserRoles: [userRoles.installer.admin, userRoles.installer.owner]
  },
  {
    path: `${admin}/team/members/:teamMemberId?`,
    showLogo: true,
    component: TeamMembersForm,
    allowedUserTypes: [userTypes.installer],
    allowedUserRoles: [userRoles.installer.admin, userRoles.installer.owner]
  },
  {
    path: `${admin}${swiftlink}`,
    showLogo: true,
    component: SwiftLink,
    allowedUserTypes: [userTypes.installer],
    allowedUserRoles: [userRoles.installer.admin, userRoles.installer.owner]
  },
  {
    path: settings,
    showLogo: true,
    component: Settings,
    allowedUserTypes: [userTypes.installer]
  },
  {
    path: start,
    showLogo: true,
    component: StartWrapper,
    allowedUserTypes: [userTypes.installer],
    hideHeader: true
  },
  {
    path: `${apply}`,
    component: ApplyWrapper,
    allowedUserTypes: ALL_USER_TYPES,
    allowedPermissions: [permissionsEnum.CREATE_CUSTOMER],
    hideHeader: true
  },
  {
    path: `${apply}/:customerId?/:section?`,
    component: ApplyWrapper,
    allowedUserTypes: ALL_USER_TYPES,
    hideHeader: true
  },
  {
    path: `${customer}`,
    component: Customer,
    allowedUserTypes: ALL_USER_TYPES,
    allowedPermissions: [permissionsEnum.CREATE_CUSTOMER]
  },
  {
    path: `${customer}/:customerId?`,
    component: Customer,
    allowedUserTypes: ALL_USER_TYPES,
    allowedPermissions: [permissionsEnum.VIEW_CUSTOMER]
  },
  {
    path: `${setup}/:customerId?/:section?`,
    component: SetupGuard,
    allowedUserTypes: ALL_USER_TYPES,
    hideHeader: true
  },
  {
    path: `${sharePendingState}/:customerId`,
    component: SharePendingState,
    allowedUserTypes: ALL_USER_TYPES,
    hideHeader: true
  },
  {
    path: logout,
    component: Logout,
    allowedUserTypes: ALL_USER_TYPES
  },
  {
    path: pipeline,
    showLogo: true,
    component: Pipeline,
    allowedUserTypes: [userTypes.installer]
  },
  {
    path: expired,
    showLogo: true,
    component: TokenExpired,
    allowedUserTypes: ALL_USER_TYPES
  },
  {
    path: passwordForgotten,
    showLog: true,
    component: ForgotPassword,
    allowedUserTypes: ALL_USER_TYPES
  },
  {
    path: passwordForgottenEmail,
    showLog: true,
    component: ForgotPasswordEmail,
    allowedUserTypes: ALL_USER_TYPES
  },
  {
    path: `${quote}/:customerId?`,
    component: Quote,
    allowedUserTypes: ALL_USER_TYPES,
    allowedPermissions: [permissionsEnum.VIEW_ESTIMATE]
  },
  {
    path: onboarding,
    component: Onboarding,
    allowedUserTypes: [userTypes.installer],
    allowedUserRoles: [userRoles.installer.admin, userRoles.installer.owner],
    featureFlag: featureFlags.onboardingFlag
  },
  {
    path: `${dashboard}/:customerId?`,
    component: DashboardGuard,
    allowedUserTypes: ALL_USER_TYPES,
    allowedPermissions: [
      permissionsEnum.VIEW_PROJECTS,
      permissionsEnum.COMPLETE_PROJECTS
    ]
  },
  {
    path: `${dashboard}/:customerId/:projectId`,
    component: ProjectDetail,
    allowedUserTypes: ALL_USER_TYPES,
    allowedPermissions: [
      permissionsEnum.VIEW_PROJECTS,
      permissionsEnum.COMPLETE_PROJECTS
    ]
  },
  {
    path: `${documentResolution}/:customerId`,
    component: UploadDocumentsScreen,
    allowedUserTypes: [userTypes.borrower, userTypes.customer]
  },
  {
    path: `${application}/:applicationId`,
    component: ApplicationToCustomerScreen,
    allowedUserTypes: [userTypes.installer]
  },
  {
    path: `${returnFromContract}/:customerId/:sourceRoute/:signatorRole?`,
    component: ReturnFromContractFrame,
    allowedUserTypes: ALL_USER_TYPES,
    noLogin: true
  },
  {
    path: `${contract}/:customerId/:sourceRoute/:signatorRole?`,
    component: ContractFrame,
    allowedUserTypes: ALL_USER_TYPES
  },
  {
    path: resources,
    showLogo: true,
    component: Resources,
    allowedUserTypes: ALL_USER_TYPES
  },
  {
    path: `${resources}${swiftlink}`,
    showLogo: true,
    component: SwiftLink,
    allowedUserTypes: [userTypes.installer]
  },
  {
    path: `${verify}/:customerId`,
    component: VerifyIdentity,
    allowedUserTypes: ALL_USER_TYPES
  },
  {
    path: `${verifyCoApp}/:customerId`,
    component: LoanIdentityVerification,
    allowedUserTypes: ALL_USER_TYPES,
    hideHeader: true
  },
  {
    path: `${verifyIdentity}/:customerId`,
    component: ResubmitSsn,
    allowedUserTypes: ALL_USER_TYPES,
    hideHeader: true
  },
  // Lease Routes
  {
    path: `${lease}/new-estimate`,
    component: LeaseStateSelection, // State Selection
    allowedUserTypes: ALL_USER_TYPES,
    hideHeader: true
  },
  {
    path: `${lease}${customer}/:engagementId?`,
    component: LeaseCustomerInfo, // Customer Info
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}${estimate}/:engagementId?`,
    component: LeaseEstimateForm,
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}${estimate}/preview/:engagementId`,
    component: LeaseEstimatePreview, // Estimate Preview
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}${estimate}/handoff/:engagementId`,
    component: LeaseEstimateHandoff, // Estimate Handoff
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}${estimate}/review/:engagementId`,
    component: LeaseEstimateReview, // Estimate Review
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}${estimate}/send/:engagementId`,
    component: LeaseSendEngagement, // Estimate Review
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}${estimate}/sent/:engagementId`,
    component: LeaseEstimateSent, // Estimate Review
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}/application/approved/:engagementId`,
    component: LeaseApplicationApproved, // Estimate Review
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}${pipeline}`,
    component: LeasePipeline, // Estimate Review
    allowedUserTypes: [userTypes.installer],
    hideHeader: true
  },
  {
    path: `${lease}${apply}/handoff`,
    component: LeaseApplicationHandoff,
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}${apply}/:engagementId?`,
    component: LeaseCreditApplication, // Estimate Review
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}${apply}/:engagementId/decision/:creditRequestId`,
    component: LeaseCreditDecision,
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}${apply}/:engagementId/decision/:creditRequestId/complete`,
    component: LeaseCreditDecisionDeclinedOrBlocked,
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}${estimate}/:engagementId/select`,
    component: LeaseSelectEstimate,
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}/:engagementId${offer}/:estimateId`,
    component: LeaseOverviewOfTerms,
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}/customer/:engagementId/dashboard/:event?`,
    component: LeaseDashboard,
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}/:engagementId/invoice`,
    component: LeaseAddInvoice,
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}/:engagementId/systems`,
    component: LeaseConfirmSystemInfo,
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}/:engagementId/certification`,
    component: LeaseCertifyCompletion,
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}/financing/:engagementId/complete`,
    component: LeaseFinancingComplete,
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}/:engagementId/eaccess`,
    component: LeaseConfirmMessageReceipt,
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${lease}/estimate/:engagementId/saved`,
    component: LeaseEstimateSaved,
    allowedUserTypes: ALL_USER_TYPES_PLUS_LEASE_CUSTOMER,
    hideHeader: true
  },
  {
    path: `${newExperience}`,
    component: NewExperienceScreen,
    allowedUserTypes: [userTypes.installer]
  }
]

export default privateRoutesConfig
