/* eslint-disable complexity */
// @flow
import React, { Component } from 'react'
import { get, isEmpty, flow, memoize } from 'lodash'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import ScriptTag from 'react-script-tag'
import * as AnalyticsHelper from 'util/AnalyticsHelper'
import userTypes from 'constants/enums/userTypes'
import featureFlags from 'constants/enums/featureFlags'
import sessionStorageKeys from 'constants/enums/sessionStorageKeys'
import accessModeKeys from 'constants/enums/accessModeKeys'
import {
  getUserAccessToken,
  getClaimsFromAccessToken,
  accessTokenIsLeaseCustomer,
  setCookiePrefixEnabled
} from 'util/SessionHelper'
import { isBorrower } from 'util/CustomerHelper'
import Loadable from 'util/Loadable'
import { getAccessMode } from 'util/AccessModeHelper'
import AppHelper from 'util/AppHelper'
import UserContext from 'util/UserContext'
import Banner from 'components/shared/Banner'
import Loading from 'components/shared/Loading'
import OptionalQuery from 'components/shared/OptionalQuery'
import GenericErrorPage from 'components/shared/GenericErrorPage'
import MessageContainer from 'components/shared/MessageContainer'
import ErrorHandlingPage from 'components/shared/ErrorHandlingPage'
import ThemeContext from 'util/ThemeContext'
import withUnstatedContainer from 'components/shared/UnstatedContainerHOC'
import AccessModeContainer from 'store/AccessModeContainer'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import l10n from 'properties/translations'
import { defaultScope } from 'properties/p3x'
import NoLoanProductsAlert from 'components/shared/NoLoanProductsAlert'
import GetMeWithBranches from 'queries/GetMeWithBranches.graphql'
import ChannelPartnerGuest from 'queries/ChannelPartnerGuest.graphql'
import {
  privateRoutesConfig,
  publicRoutesConfig,
  sidebarConfig,
  PrivateRoute,
  PrequalificationRoute
} from 'appConfig'
import { appCuesIdentify, appCuesPage } from 'util/AppCuesHelper'
import { makeSafeSessionStorage } from 'util/makeSafeStorage'
import {
  getTapHostByApplyHost,
  isHostNameSwiftlinks,
  isBasePath,
  isRootOrLoginPathName,
  getAppVersionString,
  getAppVersionCommitHash,
  inP3xIframe,
  inIframe
} from 'util/EnvironmentHelper'
import {
  getAllowDenyHostnamesConfig,
  shouldHostnameBeAllowed
} from 'util/PrequalificationHelper'
import { HeaderContext, hiddenPaths } from 'util/HeaderContext'
import Message from 'components/shared/Message'
import permissionsEnum from 'constants/enums/permissions'
import { portalLink } from 'util/NavigationHelper'
import { channelPartnerHasBlockOpportunityCreationFlag } from 'screens/apply/ChannelPartnerHelper'
import { cookiePrefixEnabled, fullStoryEnabled } from 'util/FeatureHelper'
import { setUseFullHostnameOnAccessTokenCookie } from './util/SessionHelper'
import { fullHostnameOnAccessTokenCookieEnabled } from './util/FeatureHelper'
import Routes from 'util/Routes'
import basePaths from 'constants/enums/basePaths'

const safeSessionStorage = makeSafeSessionStorage()
type PrivateRouteConfig = privateRoutesConfig.RouteConfig

type AppProps = {
  accessModeContainer: {
    demoMode: boolean,
    setMode: Function,
    state: {
      accessMode: string
    }
  },
  themeColors: {
    primaryColor: string,
    secondaryColor: string
  },
  ldClient: {
    allFlags: Function,
    identify: Function,
    on: Function,
    waitForInitialization: Function
  },
  location: Object,
  ldFlags: Object
}

type AppState = {
  modalKey: ?string,
  currentTheme: string | null,
  showHeader: boolean,
  error: Error | null,
  loading: boolean,
  loadingLDUser: boolean
}

export type HeaderOverrideOptions = {
  hidden: boolean
}

const ALL_USER_TYPES = Object.values(userTypes)
const HeaderView = Loadable(import('screens/layout/Header'), null)

class App extends Component<AppProps, AppState> {
  static defaultProps = {
    themeColors: null
  }

  state = {
    currentTheme: null,
    error: null,
    loading: true,
    modalKey: null,
    showHeader: true,
    loadingLDUser: true
  }

  componentDidMount() {
    const { hostname, pathname } = window.location
    const shouldRedirect =
      isHostNameSwiftlinks(hostname) && isRootOrLoginPathName(pathname)
    if (shouldRedirect) {
      window.location.href = getTapHostByApplyHost(hostname)
    }

    document.addEventListener('visibilitychange', this.onVisibilityChange)
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  }

  componentDidUpdate(props) {
    const {
      location: { pathname },
      ldFlags
    } = this.props
    const {
      location: { pathname: previousLocation }
    } = props
    if (get(ldFlags, featureFlags.appCues)) {
      if (pathname !== previousLocation) appCuesPage()
    }
  }

  // If app state is out of sync with local storage when tab is opened, then refresh the state
  onVisibilityChange = () => {
    const {
      accessModeContainer: {
        state: { accessMode },
        setMode
      }
    } = this.props
    const cachedAccessMode = getAccessMode()
    if (!document.hidden && cachedAccessMode !== accessMode) {
      setMode(cachedAccessMode)
    }
  }

  toggleHeader = () =>
    this.setState(prevState => ({
      ...prevState,
      showHeader: !prevState.showHeader
    }))

  overrideHeader = (options: HeaderOverrideOptions) =>
    this.setState({
      showHeader: !options.hidden
    })

  resetHeader = () =>
    this.setState({
      showHeader: true
    })

  // Filters items by whether or not they have a feature flag enabled
  filterByFlagEnabled = (item: PrivateRouteConfig) =>
    !item.featureFlag || this.props.ldFlags[item.featureFlag]

  // Filters items by whether or not the user has a role enabled
  filterByRoleEnabled = roles => item =>
    !Array.isArray(item.allowedUserRoles) ||
    item.allowedUserRoles.some(r => roles.includes(r))

  toggleModal = (modalKey = null) => {
    this.setState({
      modalKey
    })
  }

  identifyLdUser = async ({
    userType,
    userId,
    emailAddress,
    partnerId,
    partnerName,
    partnerCode,
    aggregatorCode,
    aggregatorCodes,
    salespersonId
  }) => {
    const { ldClient } = this.props
    const { loadingLDUser } = this.state
    if (userType && userId) {
      const version = getAppVersionString()
      const launchDarklyUser = {
        key: `${userType.toLowerCase()}-${userId}`,
        email: emailAddress,
        custom: {
          version,
          aggregatorCode,
          aggregatorCodes,
          userType: userType.toLowerCase(),
          userId,
          partnerId,
          partnerName,
          partnerCode,
          commitHash: getAppVersionCommitHash(),
          salespersonId
        }
      }

      await ldClient.identify(launchDarklyUser)
      if (loadingLDUser) {
        this.setState({ loadingLDUser: false })
      }
    }
  }

  showSnackbarMessages = memoize(
    (demoMode, me) =>
      !demoMode && get(me, 'accessModes', []).includes(accessModeKeys.live),
    (demoMode, me) =>
      [demoMode.toString(), get(me, 'accessModes', []).join('-')].join('-')
  )

  showNoLoanProductsMessage = memoize(
    me => !get(me, 'branch.loanProducts.homeCount')
  )

  showPrequalificationDisabledMessage = memoize(
    (channelPartner, me) =>
      !this.showNoLoanProductsMessage(me) &&
      channelPartnerHasBlockOpportunityCreationFlag(channelPartner.flags),
    (channelPartner, me) =>
      [
        !this.showNoLoanProductsMessage(me).toString(),
        get(channelPartner, 'flags', []).join('-')
      ].join('-')
  )

  showPSZeroMessage = memoize(
    (channelPartner, me) =>
      !this.showNoLoanProductsMessage(me) &&
      !this.showPrequalificationDisabledMessage(channelPartner, me) &&
      !me.permissions?.includes(permissionsEnum.P2_ZERO_PREQUAL_ACCESS),
    (channelPartner, me) =>
      [
        !this.showNoLoanProductsMessage(me).toString(),
        !this.showPrequalificationDisabledMessage(
          channelPartner,
          me
        ).toString(),
        get(me, 'permissions', []).join('-')
      ].join('-')
  )

  setChannelPartnerSessionStorage = partnerId => {
    if (
      partnerId !==
      safeSessionStorage.getItem(sessionStorageKeys.channelPartnerId)
    ) {
      safeSessionStorage.setItem(sessionStorageKeys.channelPartnerId, partnerId)
    }
  }

  setReferrerDomainSessionStorage = () => {
    if (!safeSessionStorage.getItem(sessionStorageKeys.referrerDomain)) {
      const urlParams = new URLSearchParams(window.location.search)
      const referrerDomain = urlParams.get('refdom') || ''
      safeSessionStorage.setItem(
        sessionStorageKeys.referrerDomain,
        referrerDomain
      )
    }
  }

  validateHeaderPaths = path => {
    const formattedPath = `/${path.split('/')[1]}`
    return -hiddenPaths.indexOf(formattedPath) > -1
  }

  render_appContent = (
    { me = {}, channelPartner = {}, customer = {} },
    getTheme
  ) => {
    const { showHeader } = this.state
    const {
      themeColors,
      accessModeContainer: { demoMode },
      ldFlags,
      location: { pathname }
    } = this.props

    // Set References
    const roles = get(me, 'userRoles', [])
    const channelPartnerTheme = get(channelPartner, 'theme', null)
    const aggregatorCodes = get(channelPartner, 'aggregatorCodes')
    const hostName = get(channelPartner, 'hostName')
    const userType = get(me, 'userType')
    const userId = get(me, 'userId')
    const { firstName, lastName, emailAddress, isSalesforceIdentityEnabled } =
      me

    const isNewExperienceRoute =
      window.location.pathname === basePaths.newExperience
    // If the following conditions are satisfied, load p2x-root-config and P3X Header/1D Global Nav, instead of default P2X header
    const showP3xGlobalNav =
      isSalesforceIdentityEnabled &&
      !isNewExperienceRoute &&
      !isBorrower(me) &&
      !inP3xIframe() &&
      !inIframe() &&
      this.validateHeaderPaths(pathname)
    const rootElem = document.getElementById('root')
    const headerElem = document.getElementById(
      'single-spa-application:@solarmosaic-mfe/header'
    )

    if (showP3xGlobalNav) {
      if (rootElem) {
        rootElem.classList.add('p3xGlobalNavEnabled')
      }
      if (headerElem) {
        headerElem.style.display = 'block'
      }
    } else {
      if (rootElem) {
        rootElem.classList.remove('p3xGlobalNavEnabled')
      }
      if (headerElem) {
        headerElem.style.display = 'none'
      }
    }

    const Header = () => {
      // Public routes will have no me available
      if (!me) return null

      if (showP3xGlobalNav) {
        return <ScriptTag src="/js/mfe/p2x-root-config.js" />
      }

      if (this.validateHeaderPaths(pathname)) {
        return (
          <HeaderView
            sidebarNavLinksSections={sidebarConfig(channelPartner, ldFlags).map(
              section =>
                section
                  .filter(this.filterByFlagEnabled)
                  .filter(this.filterByRoleEnabled(roles))
                  .filter(AppHelper.filterByLiveModeEnabled(me.accessModes))
            )}
          />
        )
      }
      return null
    }

    // Set Theme
    const currentTheme = channelPartnerTheme
      ? getTheme(channelPartnerTheme)
      : getTheme(themeColors)

    // Set session storage info
    this.setChannelPartnerSessionStorage(sessionStorageKeys.channelPartnerId)
    this.setReferrerDomainSessionStorage()

    if (get(ldFlags, featureFlags.appCues)) {
      appCuesIdentify(userId, {
        firstName,
        lastName,
        emailAddress,
        userType
      })
    }

    const isLeaseSetupPage =
      pathname.includes('/admin') &&
      aggregatorCodes &&
      aggregatorCodes.includes(defaultScope)

    const filteredPrivateRoutes = privateRoutesConfig
      .filter(this.filterByFlagEnabled)
      .filter(this.filterByRoleEnabled(roles))

    const routesWithoutPermission = privateRoutesConfig.filter(
      el => !filteredPrivateRoutes.includes(el)
    )

    return (
      <MuiThemeProvider theme={currentTheme}>
        {get(ldFlags, featureFlags.maintainanceMode) ? (
          <GenericErrorPage
            title={l10n.notFound.maintenanceTitle}
            descriptionOverviewText={l10n.notFound.maintenanceDescriptionLn1}
            descriptionDetailText={l10n.notFound.maintenanceDescriptionLn2}
            showBackButton={false}
            userId={userId}
            showHeader={showHeader}
            isBorrower={isBorrower(me)}
            toggleHeader={this.toggleHeader}
          />
        ) : (
          <Switch>
            {/* Public Routes */}
            {publicRoutesConfig.map(r => (
              <Route exact key={r.path} {...r} />
            ))}
            <UserContext.Provider value={{ me, channelPartner, customer }}>
              <HeaderContext.Provider
                value={{
                  showHeader,
                  toggleHeader: this.toggleHeader,
                  overrideHeader: this.overrideHeader,
                  resetHeader: this.resetHeader
                }}
              >
                {showHeader && !isLeaseSetupPage && <Header />}
                <Switch>
                  {filteredPrivateRoutes.map(route => (
                    <PrivateRoute
                      {...route}
                      exact
                      key={route.path}
                      toggleHeader={this.toggleHeader}
                      overrideHeader={this.overrideHeader}
                      ldFlags={this.props.ldFlags}
                    />
                  ))}

                  {/* Prequal Guard for routes without permission */}
                  {routesWithoutPermission.map(route => {
                    return (
                      <PrivateRoute
                        exact
                        key={route.path}
                        path={route.path}
                        allowedUserTypes={route.allowedUserTypes}
                        component={() => (
                          <Route
                            render={props => (
                              <ErrorHandlingPage
                                {...props}
                                userId={userId}
                                showHeader={showHeader}
                                me={me}
                                toggleHeader={this.toggleHeader}
                              />
                            )}
                          />
                        )}
                      />
                    )
                  })}

                  {/* Prequalification Form route */}
                  <PrivateRoute
                    {...PrequalificationRoute}
                    overrideHeader={this.overrideHeader}
                  />

                  <PrivateRoute
                    allowedUserTypes={ALL_USER_TYPES}
                    component={() => (
                      <Route
                        render={props => (
                          <ErrorHandlingPage
                            {...props}
                            userId={userId}
                            showHeader={showHeader}
                            me={me}
                            toggleHeader={this.toggleHeader}
                          />
                        )}
                      />
                    )}
                  />
                </Switch>
                {userType === userTypes.installer &&
                  this.showSnackbarMessages(demoMode, me) && (
                    <>
                      <NoLoanProductsAlert
                        me={me}
                        open={this.showNoLoanProductsMessage(me)}
                      />
                      {this.showPSZeroMessage(channelPartner, me) && (
                        <Message
                          text={l10n.snackbarPSZeroTexts.snackbarPSZeroMessage(
                            portalLink(hostName, '/')
                          )}
                        />
                      )}
                      {this.showPrequalificationDisabledMessage(
                        channelPartner,
                        me
                      ) && (
                        <Message
                          text={l10n.snackbarPrequalDisabledTexts.message}
                        />
                      )}
                    </>
                  )}
                <MessageContainer />
                {demoMode && <Banner />}
              </HeaderContext.Provider>
            </UserContext.Provider>
          </Switch>
        )}
      </MuiThemeProvider>
    )
  }

  renderWithTheme = (
    { me = {}, channelPartner = {}, customer = {} },
    getTheme
  ) => {
    const { ldFlags } = this.props

    const token = getUserAccessToken()

    // Retrieve the Swiftlinks hostname from the pathname
    const pathname = window && window.location.pathname
    const swiftlinksHostname = pathname.substring(1)
    // This query should run for Swiftlinks, to find out the channel partner theme.
    const shouldRunChannelPartnerQuery = () => {
      if (!token && !isBasePath(pathname)) {
        const allowDenyHostnamesConfig = getAllowDenyHostnamesConfig(ldFlags)

        return shouldHostnameBeAllowed(
          allowDenyHostnamesConfig,
          swiftlinksHostname
        )
      }
      return false
    }

    return (
      <OptionalQuery
        query={ChannelPartnerGuest}
        variables={{
          hostName: swiftlinksHostname
        }}
        runQuery={() => shouldRunChannelPartnerQuery()}
      >
        {({ loading, error, data: channelPartnerData }) => {
          if (loading) return <Loading />
          if (error)
            return (
              <ErrorHandlingPage me={me} toggleHeader={this.toggleHeader} />
            )

          const channelPartnerProp = channelPartnerData
            ? channelPartnerData.channelPartner
            : channelPartner

          return this.render_appContent(
            {
              me,
              channelPartner: channelPartnerProp,
              customer
            },
            getTheme
          )
        }}
      </OptionalQuery>
    )
  }

  shouldLoadBranches(token) {
    if (!token) return false

    try {
      const metadata = getClaimsFromAccessToken()
      if (accessTokenIsLeaseCustomer(metadata)) {
        // This is a Lease JWT
        return false
      }
    } catch (e) {
      return true
    }
    return true
  }

  render() {
    const { ldFlags, location } = this.props
    const { loadingLDUser } = this.state

    // Set cookiePrefixEnabled
    if (cookiePrefixEnabled(ldFlags)) setCookiePrefixEnabled(true)

    // Set full hostname for access token cookie
    if (fullHostnameOnAccessTokenCookieEnabled(ldFlags)) {
      setUseFullHostnameOnAccessTokenCookie(true)
    }

    const token = getUserAccessToken()

    return (
      <ThemeContext.Consumer>
        {({ getTheme }) => (
          <>
            <OptionalQuery
              query={GetMeWithBranches}
              runQuery={this.shouldLoadBranches(token)}
              partialRefetch
            >
              {({ data = {}, loading }) => {
                const channelPartner = get(data, 'channelPartner', {})
                const me = get(data, 'me', {})
                const customer = get(data, 'customer', {})
                const partnerId = get(channelPartner, 'id')
                const partnerName = get(channelPartner, 'name')
                const partnerCode = get(channelPartner, 'hostName')
                const emailAddress = get(me, 'emailAddress')
                const userType = get(me, 'userType')
                const userId = get(me, 'userId')
                const isSalesforceIdentityEnabled = get(
                  me,
                  'isSalesforceIdentityEnabled'
                )
                const aggregatorCode = get(customer, 'aggregatorCode')
                const aggregatorCodes = get(channelPartner, 'aggregatorCodes')
                const salespersonId = get(customer, 'salesperson.id')

                // Identify launchdarkly user
                this.identifyLdUser({
                  userType,
                  userId,
                  emailAddress,
                  partnerId,
                  partnerName,
                  partnerCode,
                  aggregatorCode,
                  aggregatorCodes,
                  salespersonId
                })

                if (fullStoryEnabled(ldFlags)) {
                  AnalyticsHelper.initFullStory({
                    partnerName,
                    userId
                  })
                }

                if (
                  loading ||
                  isEmpty(ldFlags) ||
                  (Boolean(userId) && loadingLDUser)
                ) {
                  return <Loading />
                }

                if (
                  isSalesforceIdentityEnabled &&
                  location?.state?.fromLogin === true
                ) {
                  return <Redirect to={Routes.newExperience()} />
                }

                return this.renderWithTheme(data, getTheme)
              }}
            </OptionalQuery>
          </>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default flow(
  withRouter,
  withLaunchDarkly
)(
  withUnstatedContainer(App, {
    accessModeContainer: AccessModeContainer
  })
)
