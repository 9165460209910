// @flow
/* eslint-disable no-undef, no-unused-vars, class-methods-use-this */
import React, { Component } from 'react'
import type { Node } from 'react'

type Props = {
  children: Node
}

export default class WebsiteMonitoring extends Component<Props> {
  UNSAFE_componentWillMount() {
    ineum('page', window.location.pathname)
    ineum('startSpaPageTransition')
  }

  componentDidMount() {
    ineum('meta', 'content', window.location.pathname)
    ineum('endSpaPageTransition', {
      status: 'completed',
      url: window.location.href
    })
  }

  UNSAFE_componentWillUpdate() {
    ineum('page', window.location.pathname)
    ineum('startSpaPageTransition')
  }

  componentDidUpdate() {
    ineum('meta', 'content', window.location.pathname)
    ineum('endSpaPageTransition', {
      status: 'completed',
      url: window.location.href
    })
  }

  render() {
    return this.props.children || null
  }
}
