// @flow
import React, { Component } from 'react'
import {
  Button,
  Grid,
  NativeSelect,
  FormHelperText,
  FormControl,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { flow, get } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import padStart from 'lodash/padStart'
import { ValidatorForm } from 'react-material-ui-form-validator'
import NumberFormat from 'react-number-format'
import TextValidator from 'components/shared/TextValidator'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import placeholders from 'properties/placeholders'
import {
  DEBOUNCE_TIME,
  DOB_DAY_VALIDATORS,
  DOB_YEAR_VALIDATORS,
  FULL_SSN_VALIDATORS,
  LAST_4_SSN_VALIDATORS,
  CIRCULAR_PROGRESS_SIZE,
  DOB_FULL_VALIDATORS,
  SSN_INPUT_PATTERN,
  EMPTY_STRING
} from 'properties/properties'
import customerFields from 'constants/enums/customerFields'
import fieldMasks from 'constants/enums/fieldMasks'
import fieldsets, { fieldsetNames } from 'constants/fieldsets/customerFieldsets'
import MobileInfo from 'util/MobileInfo'
import { singleDobFieldFlagEnabled } from 'util/FeatureHelper'
import DateHelper from 'util/DateHelper'
import formValidator from 'screens/customer/formValidator/FormValidator.js'
import { type CustomerShape } from 'screens/customer/shapes/customer'
import { lastFourSSNFlagEnabled } from 'util/FeatureHelper'
import { canSubmitVerifyIdentityForm } from './utils/verifyIdentityUtils'
import styles from './commonStyles'
import { getCustomerName } from 'util/CustomerHelper'
import UserContext from 'util/UserContext'
import { hasPartialCreditApplicationPermission } from 'util/UserHelper'

const { FIELDSET_VERIFY_IDENTITY } = fieldsetNames

type Props = {
  error: string,
  classes: {
    selectPlaceholder: string,
    input: string,
    sensitiveInfoInput: string,
    sensitiveInfoInputWebkit: string,
    invalidInformation: string
  },
  submitting: boolean,
  customer: CustomerShape,
  customerId: string,
  onSubmitForm: Function,
  fullSsnRequired: boolean,
  dobRequired: boolean,
  languageIsEs: boolean
}

type State = {
  formValues: {
    verifySsn: string,
    dobMonth: string,
    dobDay: string,
    dobYear: string,
    ldFlags: Object
  }
}

class VerifyIdentityForm extends Component<Props, State> {
  form = React.createRef()

  static defaultProps = {
    error: EMPTY_STRING,
    fullSsnRequired: false,
    dobRequired: true
  }

  state = {
    formValues: {
      verifySsn: EMPTY_STRING,
      dobMonth: EMPTY_STRING,
      dobDay: EMPTY_STRING,
      dobYear: EMPTY_STRING
    }
  }

  onFieldEdit = (name, { target: { checked, value } }) => {
    if (name === customerFields.dobFull) {
      const dobMonth = DateHelper.monthFromMMDDYYYYDate(value).toString()
      const dobDay = DateHelper.dayFromMMDDYYYYDate(value).toString()
      const dobYear = DateHelper.yearFromMMDDYYYYDate(value).toString()

      this.setState({
        formValues: {
          ...this.state.formValues,
          dobMonth,
          dobDay,
          dobYear
        }
      })
    } else {
      this.setState({
        formValues: {
          ...this.state.formValues,
          [name]: checked || value
        }
      })
    }
  }

  addCustomValidators = () => {
    ValidatorForm.addValidationRule(
      'isValidCalendarDate',
      formValidator.isValidCalendarDate
    )

    ValidatorForm.addValidationRule('isYearValid', formValidator.isYearValid)

    ValidatorForm.addValidationRule(
      'noBlankSpaces',
      formValidator.noBlankSpaces
    )
  }

  isRequiredField = field =>
    fieldsets[FIELDSET_VERIFY_IDENTITY].requiredFields.includes(field)

  labelIsRequired = () => false

  canSubmit = () => {
    const {
      formValues: { verifySsn, dobMonth, dobYear, dobDay }
    } = this.state
    const { fullSsnRequired, dobRequired } = this.props
    return canSubmitVerifyIdentityForm({
      verifySsn,
      dobMonth,
      dobYear,
      dobDay,
      fullSsnRequired,
      dobRequired
    })
  }

  componentDidMount() {
    this.addCustomValidators()
  }

  RenderDobField = ({
    classes,
    singleDobField,
    translations,
    formValues,
    dobRequired
  }) => {
    if (!dobRequired) return null
    return singleDobField ? (
      <NumberFormat
        fullWidth
        id={customerFields.dobFull}
        name={customerFields.dobFull}
        format={fieldMasks.dateOfBirth}
        placeholder={placeholders.dobFull}
        customInput={TextValidator}
        label={translations.customer.dob}
        type="tel"
        validators={[...DOB_FULL_VALIDATORS]}
        errorMessages={[
          translations.customer.dobYearErrorMessage,
          translations.customer.dobDayErrorMessage,
          translations.customer.dobDayErrorMessage
        ]}
        InputLabelProps={{ shrink: true }}
        onChange={event => this.onFieldEdit(customerFields.dobFull, event)}
        data-testid="single-dob-field"
      />
    ) : (
      <Grid container spacing={2} data-testid="dob-fields-container">
        <Grid item xs={12} className={classes.labelCtn}>
          <FormHelperText>{translations.customer.dob}</FormHelperText>
        </Grid>
        <Grid item xs={6} sm={4} className={classes.fieldCtn}>
          <FormControl
            required={this.isRequiredField(customerFields.dobMonth)}
            fullWidth
          >
            <NativeSelect
              id={customerFields.dobMonth}
              defaultValue=""
              onChange={event =>
                this.onFieldEdit(customerFields.dobMonth, event)
              }
              name={customerFields.dobMonth}
              label={translations.customer.dobMonth}
              className={
                formValues.dobMonth ? EMPTY_STRING : classes.selectPlaceholder
              }
            >
              <option key="placeholder" value="" disabled>
                {translations.customer.dobMonth}
              </option>
              {translations.monthNames.map((name, index) => (
                <option key={name} value={index + 1}>
                  {name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={4} className={classes.fieldCtn}>
          <NumberFormat
            id={customerFields.dobDay}
            customInput={TextValidator}
            fullWidth
            type="tel"
            onChange={event => this.onFieldEdit(customerFields.dobDay, event)}
            name={customerFields.dobDay}
            format={fieldMasks.dobDay}
            InputLabelProps={{ required: this.labelIsRequired() }}
            InputProps={{
              classes: { input: classes.input }
            }}
            placeholder={translations.customer.dobDay}
            validators={DOB_DAY_VALIDATORS}
            errorMessages={[
              translations.customer.dobDayErrorMessage,
              translations.customer.dobDayErrorMessage
            ]}
            required={this.isRequiredField(customerFields.dobDay)}
          />
        </Grid>
        <Grid item xs={3} sm={4} className={classes.fieldCtn}>
          <NumberFormat
            id={customerFields.dobYear}
            customInput={TextValidator}
            fullWidth
            type="tel"
            onChange={event => this.onFieldEdit(customerFields.dobYear, event)}
            name={customerFields.dobYear}
            format={fieldMasks.dobYear}
            InputLabelProps={{ required: this.labelIsRequired() }}
            InputProps={{
              classes: { input: classes.input }
            }}
            placeholder={translations.customer.dobYear}
            validators={DOB_YEAR_VALIDATORS}
            errorMessages={[
              translations.customer.dobYearErrorMessage,
              translations.customer.dobYearErrorMessage
            ]}
            required={this.isRequiredField(customerFields.dobYear)}
          />
        </Grid>
      </Grid>
    )
  }

  render() {
    const {
      error,
      classes,
      customer,
      customerId,
      submitting,
      onSubmitForm,
      fullSsnRequired,
      ldFlags,
      translations,
      languageIsEs,
      dobRequired
    } = this.props
    const { formValues } = this.state
    const addPadding = toPad => padStart(toPad.trim(), 2, '0')
    const singleDobField = singleDobFieldFlagEnabled(ldFlags)
    const customerName = getCustomerName(customer)

    return (
      <UserContext.Consumer>
        {userContext => {
          const permissions = get(userContext, 'me.permissions', [])

          const userHasPartialCreditAppPermission =
            hasPartialCreditApplicationPermission(permissions)

          const lastFourSSNEnabled =
            lastFourSSNFlagEnabled(ldFlags) && userHasPartialCreditAppPermission

          return (
            <ValidatorForm
              ref={r => {
                this.form = r
              }}
              debounceTime={DEBOUNCE_TIME}
              onSubmit={() => {
                const { verifySsn, dobMonth, dobYear, dobDay } = formValues
                onSubmitForm({
                  customerId,
                  ssn: verifySsn,
                  dobDay: addPadding(dobDay),
                  dobYear,
                  dobMonth: addPadding(dobMonth)
                })
              }}
              data-testid="verify-identity-form"
            >
              <Grid container justify="center" spacing={1}>
                <Grid
                  container
                  item
                  xs={12}
                  sm={lastFourSSNEnabled ? 8 : 12}
                  md={lastFourSSNEnabled ? 6 : 12}
                >
                  {lastFourSSNEnabled && (
                    <Grid item xs={12} className={classes.marginBottom}>
                      <Typography variant="caption">
                        {
                          translations.apply.formStatus.verifyIdentity
                            .applicantLabel
                        }
                      </Typography>
                      <Typography variant="body2">{customerName}</Typography>
                    </Grid>
                  )}
                  <Grid item xs={12} className={classes.marginBottom}>
                    <NumberFormat
                      fullWidth
                      customInput={TextValidator}
                      format={
                        fullSsnRequired
                          ? fieldMasks.verifySsn
                          : fieldMasks.last4Ssn
                      }
                      mask=""
                      id={customerFields.verifySsn}
                      type="tel"
                      label={
                        fullSsnRequired
                          ? translations.customer.verifySsn
                          : translations.customer.verifySsnLast4
                      }
                      onChange={event =>
                        this.onFieldEdit(customerFields.verifySsn, event)
                      }
                      name={customerFields.verifySsn}
                      placeholder={
                        fullSsnRequired
                          ? placeholders.verifySsn
                          : placeholders.last4Ssn
                      }
                      validators={
                        fullSsnRequired
                          ? FULL_SSN_VALIDATORS
                          : LAST_4_SSN_VALIDATORS
                      }
                      errorMessages={[
                        translations.customer.verifySsnErrorMessage
                      ]}
                      required={this.isRequiredField(customerFields.verifySsn)}
                      InputLabelProps={{
                        required: this.labelIsRequired(),
                        shrink: true
                      }}
                      inputProps={{
                        autoComplete: 'off',
                        pattern: SSN_INPUT_PATTERN,
                        inputMode: 'numeric',
                        className: cx({
                          [classes.mobileMarginTop]: languageIsEs,
                          [classes.sensitiveInfoInputWebkit]:
                            MobileInfo.isWebkitMobile(),
                          [classes.sensitiveInfoInput]:
                            !MobileInfo.isWebkitMobile()
                        })
                      }}
                      helperText={
                        lastFourSSNEnabled
                          ? translations.customer.ssnHelpText
                          : undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.marginBottom}>
                    {this.RenderDobField({
                      classes,
                      singleDobField,
                      translations,
                      formValues,
                      dobRequired
                    })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify="center">
                {error && (
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      paragraph
                      className={classes.invalidInformation}
                    >
                      {error}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    size="large"
                    disabled={!this.canSubmit() || submitting}
                  >
                    {submitting ? (
                      <CircularProgress
                        data-testid="continue-button-spinner"
                        size={CIRCULAR_PROGRESS_SIZE}
                      />
                    ) : (
                      <Typography data-testid="continue-button-text">
                        {lastFourSSNEnabled
                          ? translations.customer.confirm
                          : translations.customer.continue}
                      </Typography>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          )
        }}
      </UserContext.Consumer>
    )
  }
}

export default flow(withLaunchDarkly, withStyles(styles))(VerifyIdentityForm)
