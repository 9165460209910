// @flow
import React from 'react'
import { Snackbar, Button } from '@material-ui/core'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { withStyles } from '@material-ui/core/styles'
import l10n from 'properties/translations'
import { Link } from 'react-router-dom'
import { goToPortalLink } from '../../util/NavigationHelper'
import { includes, flow } from 'lodash'
import userRoles from 'constants/enums/userRoles'
import styles from './NoLoanProductsAlert.styles'

const { snackbarBranchTexts } = l10n
type Props = {
  classes: any,
  me: { userRoles: [], branches: [{ name: string }] },
  open: boolean
}

const NoLoanProductsAlert = ({ me, open, classes }: Props) => {
  const isAdmin = includes(me.userRoles, userRoles.installer.admin)
  const isOwner = includes(me.userRoles, userRoles.installer.owner)
  if (!me.branches) {
    return null
  }

  return (
    <Snackbar
      data-testid="no-loan-products-alert"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      className={classes.branchToastMessage}
      open={open}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
      message={
        <span id="message-id" className={classes.messageText}>
          {
            /**
             * ATG-311 - Disabled no products message for admin & owner
             */
            // (isAdmin || isOwner) && snackbarBranchTexts.snackBarBranchMessage(me.branches)
          }
          {!isAdmin && !isOwner && snackbarBranchTexts.snackBarMessage}
        </span>
      }
      action={
        (isAdmin || isOwner) && (
          <Button
            data-testid="go-to-portal-link"
            key="undo"
            className={classes.setUpPayment}
            size="small"
            component={Link}
            onClick={() => goToPortalLink()}
          >
            {snackbarBranchTexts.setUpTextButton}
          </Button>
        )
      }
    />
  )
}

export default flow(withApolloConsumer, withStyles(styles))(NoLoanProductsAlert)
